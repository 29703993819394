<template>
	<div class="wrapper">
		<Navbar />
		<div class="mt-5 mt-md-0">
			<router-view v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
		</div>
	</div>
</template>

<script>
	import Navbar from './sections/Navbar'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',
		components: 	{
			Navbar
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'check'
			])
		},
		methods: {
			...mapActions('auth', [
				'GetAuthUser'
			])
		},
		beforeMount()
		{
			if(this.check)
			{
				this.GetAuthUser()
			}
		}
	}
</script>

<style lang="css" scoped>
	.wrapper
	{
		margin-bottom: 150px;
		overflow-x: hidden;
	}
</style>