export default class BrowserNotification
{
	init()
	{
		if(!("Notification" in window))
		{
			console.log('This browser not support desktop notifications.')

		} else {

			if(Notification.permission !== 'denied')
			{
				Notification.requestPermission()
			}
		}
	}

	fire(obj)
	{
		this.init()

		if (Notification.permission === 'granted') 
		{
			const notification = new Notification(obj.title ?? '', {
				body: obj.text ?? 'unknown'
			})

			notification.onclick = (e) => {
				e.preventDefault()
				window.focus()
				notification.close()
			}
		}
	}
}