import { createApp } from 'vue'
import LogoSVG from './components/shared/LogoSVG'
import DataTables from './components/shared/DataTables'
import DataLists from './components/shared/DataLists'
import VueTheMask from 'vue-the-mask'
import store from './vuex'
import 'bootstrap';
window.bootstrap 	= require('bootstrap/dist/js/bootstrap.bundle.js')

window.Pusher 		= require('pusher-js')
window.moment 		= require('moment')

import Echo from './echo'
window.Echo 		= new Echo()

import Api from './api'
window.api 			= new Api()

import Toast from './toast'
window.toast  		= new Toast()

import BrowserNotification from './browser-notification'
window.bnotification	= new BrowserNotification()

import Helpers from './helpers'
window.helpers 		= new Helpers()

window._ 			= require('lodash')

window.axios        = require('axios');

window.axios.defaults.headers.common['X-Requested-With']   = 'XMLHttpRequest';

window.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/app/`;

const auth_token 	= localStorage.getItem('token');

if (auth_token) 
{
    window.axios.defaults.headers.common['Authorization']     = 'Bearer ' + auth_token;
}

require('./global-variables')
require('./directives')

import router from './routes'

import App from './Index.vue'

const app 			= createApp(App)
	.component('LogoSVG', LogoSVG)
	.component('DataTables', DataTables)
	.component('DataLists', DataLists)
	.use(VueTheMask)
	.use(router)
	.use(store)
	.mount('#app')
