const user 		= (state) => {
	return state.user
}

const token 		= (state) => {
	return state.token
}

const check 		= (state) => {
	return !! state.token;
}

const roles 		= (state) => {
	return state.roles && parseInt(state.organization_selected_id) && state.roles[parseInt(state.organization_selected_id)] ? state.roles[parseInt(state.organization_selected_id)] : []
}

const all_roles 	= (state) => {
	return state.roles
}

const organizations 	= (state) => {

	return state.organizations.map( l => {
		l.selected 		= false

		if(l.organization && l.organization.id === parseInt(state.organization_selected_id))
		{		
			l.selected 		= true
		}

		return l
	})
	.filter( l => l.organization && l.organization.status && l.status)
}

const all_organizations = (state) => {

	return state.organizations.map( l => {
		l.selected 		= false

		if(l.organization && l.organization.id === parseInt(state.organization_selected_id))
		{		
			l.selected 		= true
		}

		return l
	})
}

const organization 	= (state) => (id) => {
	return state.organizations.find( l => l.organization.id == id )
}

const organization_selected_id		= (state) => {
	return parseInt(state.organization_selected_id)
}

const organization_selected_object	= (state) => {
	return state.organizations.find( l => l.organization.id == parseInt(state.organization_selected_id) )
}

const online_users		= (state) => {
	return state.online_users
}

const has_role = (state) => (area) => {
	return state.roles.indexOf(area.toString().toLowerCase()) > -1
}

export {
	user,
	token,
	check,
	roles,
	all_roles,
	organizations,
	organization,
	organization_selected_object,
	organization_selected_id,
	online_users,
	has_role,
	all_organizations
}