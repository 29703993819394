<template>
	<div>

		<div class="card p-3 mb-3 border-0 bg-light text-left">

			<div class="row" v-if="audit.statistics">

                <div
                    class="col-12 col-lg-6"
                    v-for="(laterality, index_laterality) in ['left', 'right']"
                    :key="index_laterality"
                >

                    <h6>Mama {{ laterality == 'right' ? 'direita' : 'esquerda' }}</h6>

                    <table class="table table-hover table-sm table-responsive" v-if="audit.statistics.spreadsheet_cats && audit.statistics.ia_cats">
                        <thead class="thead-default">
                        <tr>
                            <th>Birads</th>
                            <th class="text-center">Planilha</th>
                            <th class="text-center">IA</th>
                            <th class="text-right">%</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(type, index) in ['cat_normal', 'cat_suspect', 'cat_no_exam']" :key="index">
                            <td>
                                {{ ParseName(type) }}
                            </td>
                            <td class="text-center">
                                <span v-if="audit.statistics.spreadsheet_cats[laterality]">
                                    {{audit.statistics.spreadsheet_cats[laterality][type] ?? '#' }}
                                </span>
                                <span v-else>
                                    ?
                                </span>
                            </td>
                            <td class="text-center">
                                <span v-if="audit.statistics.ia_cats[laterality]">
                                    {{ audit.statistics.ia_cats[laterality][type] ?? null }}
                                </span>
                                <span v-else>
                                    ?
                                </span>
                            </td>
                            <td class="text-right">
                                {{ Percentage(audit.statistics.spreadsheet_cats[laterality][type], audit.statistics.ia_cats[laterality][type]) }}%
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div class="d-flex d-none"
                     v-for="cat_table in [
                        { key: 'cat_0', label: 'Cat 0'},
                        { key: 'cat_1', label: 'Cat 1'},
                        { key: 'cat_2', label: 'Cat 2'},
                        { key: 'cat_3', label: 'Cat 3'},
                        { key: 'cat_4', label: 'Cat 4'},
                        { key: 'cat_5', label: 'Cat 5'},
                        { key: 'consolidated', label: 'Somatória'},
                     ]"
                     :key="cat_table.key"
                >

                    <table class="table table-hover table-sm table-responsive"  >
                        <thead>
                            <tr class="text-center">
                                <th colspan="2" rowspan="2"></th>
                                <th colspan="2">Direita Nódulo</th>
                                <th colspan="2" class="right-divisor">Direita Micro</th>
                                <th colspan="2">Esquerda Nódulo</th>
                                <th colspan="2">Esquerda Micro</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center">
                                <td colspan="2"></td>
                                <td>PL</td>
                                <td class="right-divisor">IA</td>
                                <td>PL</td>
                                <td class="right-divisor">IA</td>
                                <td>PL</td>
                                <td class="right-divisor">IA</td>
                                <td>PL</td>
                                <td>IA</td>
                            </tr>
                            <tr class="text-center">
                                <td class="text-start" colspan="2">{{ cat_table.label }}</td>
                                <td colspan="2" class="right-divisor">NÓD</td>
                                <td colspan="2" class="right-divisor">MIC</td>
                                <td colspan="2" class="right-divisor">NÓD</td>
                                <td colspan="2">MIC</td>
                            </tr>
                            <tr class="text-center"
                                v-for="row in [
                                    { label: 'TOTAL', key: 'total' },
                                    { label: 'SUSPECT', key: 'suspect' },
                                    { label: 'BENIGN', key: 'benign' }
                                ]"
                                :key="row.key"
                            >
                                <td
                                    :colspan="2"
                                    :class="{
                                        'text-start': row.key == 'total',
                                        'text-end': row.key != 'total'
                                    }"
                                >
                                    {{ row.label }}
                                </td>
                                <td>
                                    {{ audit.statistics.achados[cat_table.key].right_nod.spreadsheet[row.key] }}
                                </td>
                                <td class="right-divisor">
                                    {{ audit.statistics.achados[cat_table.key].right_nod.ia[row.key] }}
                                </td>
                                <td>
                                    {{ audit.statistics.achados[cat_table.key].right_mic.spreadsheet[row.key] }}
                                </td>
                                <td class="right-divisor">
                                    {{ audit.statistics.achados[cat_table.key].right_mic.ia[row.key] }}
                                </td>
                                <td>
                                    {{ audit.statistics.achados[cat_table.key].left_nod.spreadsheet[row.key] }}
                                </td>
                                <td class="right-divisor">
                                    {{ audit.statistics.achados[cat_table.key].left_nod.ia[row.key] }}
                                </td>
                                <td>
                                    {{ audit.statistics.achados[cat_table.key].left_mic.spreadsheet[row.key] }}
                                </td>
                                <td>
                                    {{ audit.statistics.achados[cat_table.key].left_mic.ia[row.key] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="d-grid gap-2 d-none">

                    <div class="row">

                        <div
                            class="col-6"
                            v-for="achado in [{label: 'Nódulo', key: 'nod'}, {label: 'Micro', key: 'mic'}]"
                            :key="achado.key"
                        >

                            <table class="table table-sm table-hover table-responsive">
                                <thead>
                                <tr class="text-center">
                                    <th></th>
                                    <th>Totais {{achado.label}}</th>
                                    <th>Benigno</th>
                                    <th>Suspeito</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="cat in [
                                        { key: 'cat_0', label: 'Cat 0'},
                                        { key: 'cat_1', label: 'Cat 1'},
                                        { key: 'cat_2', label: 'Cat 2'},
                                        { key: 'cat_3', label: 'Cat 3'},
                                        { key: 'cat_4', label: 'Cat 4'},
                                        { key: 'cat_5', label: 'Cat 5'}
                                    ]"
                                    :key="cat.key"
                                    class="text-center"
                                >
                                    <td>{{ cat.label }}</td>
                                    <td>{{ GetAchadoStatisticSum(achado.key, cat.key, 'total') }}</td>
                                    <td>{{ GetAchadoStatisticSum(achado.key, cat.key, 'benign') }}</td>
                                    <td>{{ GetAchadoStatisticSum(achado.key, cat.key, 'suspect') }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

				<div class="col-12 col-lg-6">

					<table class="table table-hover table-sm table-responsive">
						<thead>
							<tr>
								<th>Informações gerais</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td scope="row">Total estudos planilha</td>
								<td class="text-right">{{ audit.statistics.spreadsheet_total }}</td>
							</tr>
							<tr>
								<td scope="row">Total estudos Dicom</td>
								<td class="text-right">{{ audit.statistics.dicoms_total }}</td>
							</tr>
							<tr>
								<td scope="row">Total compatíveis</td>
								<td class="text-right">{{ audit.statistics.compatible_dicoms }}</td>
							</tr>
							<tr>
								<td scope="row">Peso NA</td>
								<td class="text-right">{{ audit.lower_na == 1 ? 'Sim' : 'Não' }}</td>
							</tr>
						</tbody>
					</table>

				</div>

				<div class="col-12 col-lg-6">

					<table class="table table-hover table-sm table-responsive" v-if="audit.statistics.spreadsheet_cats && audit.statistics.ia_cats">
						<thead>
							<tr>
								<th>Mama</th>
								<th class="text-center">Quantitativo</th>
								<th class="text-right">%</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Direita</td>
								<td class="text-center">{{ GetAllCats('right')[0] }} / {{ GetAllCats('right')[1] }}</td>
								<td class="text-right">{{ Percentage(GetAllCats('right')[0], GetAllCats('right')[1]) }}%</td>
							</tr>
							<tr>
								<td>Esquerda</td>
								<td class="text-center">{{ GetAllCats('left')[0] }} / {{ GetAllCats('left')[1] }}</td>
								<td class="text-right">{{ Percentage(GetAllCats('left')[0], GetAllCats('left')[1]) }}%</td>
							</tr>
						</tbody>
					</table>

				</div>

				<div class="col-12">

					<table class="table table-hover table-sm table-responsive">
						<thead class="thead-inverse">
							<tr>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Auditoria:</td>
								<td class="text-right">#{{ audit.id }}</td>
							</tr>
							<tr>
								<td>Tempo total:</td>
								<td class="text-right">{{ diff(audit.created_at, audit.finished_at) }}</td>
							</tr>
							<tr>
								<td>Tempo analise IA</td>
								<td class="text-right">{{ diff(audit.ai_started_at, audit.ai_finished_at) }}</td>
							</tr>
							<tr>
								<td>Criado em</td>
								<td class="text-right">{{ audit.created_at_parse }}</td>
							</tr>
							<tr>
								<td>Por</td>
								<td class="text-right">{{ audit.user.name }}</td>
							</tr>
						</tbody>
					</table>

					<h5>Observação</h5>

					<textarea class="form-control" rows="3" @blur="SaveObs" @keyup.enter="SaveObs" v-model="observation"></textarea>
					<small>Enter ou clique fora do campo para salvar.</small>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex' 
import Swal from 'sweetalert2';
require('moment-precise-range-plugin');

export default {
	name: 'AuditStatistics',
	data() {
		return {
			observation: ''
		}
	},
	props: {
		
		audit: {
			type: [Object],
			required: true
		}

	},
	computed: { 
		obs() { 
			return this.observation = this.audit.observation
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading', 'LoadingMessage'
		]),
		diff(start, end) {

			end 		= moment(end)
			start 		= moment(start) 
			
			return start.preciseDiff(end)
			
		},
		ParseName(cat) {

			switch (cat) {
				case 'cat_0':
					return 'Cat 0'
				case 'cat_1':
					return 'Cat 1'
				case 'cat_2':
					return 'Cat 2'
				case 'cat_3':
					return 'Cat 3'
				case 'cat_4':
					return 'Cat 4'
				case 'cat_5':
					return 'Cat 5'
                case 'cat_na':
                    return 'NA'
                case 'cat_no_exam':
                    return 'No exam'
                case 'cat_normal':
                    return 'Normal'
                case 'cat_suspect':
                    return 'Suspeito'
				default:
					break;
			}

		},
		GetAllCats(laterality) {

			let total_ia 			= 0
			let total_spreadsheet 	= 0

			Object.keys(this.audit.statistics.ia_cats[laterality]).forEach(e => { 
				if (e.length == 5) total_ia += this.audit.statistics.ia_cats[laterality][e] 
			})
			Object.keys(this.audit.statistics.spreadsheet_cats[laterality]).forEach(e => { 
				if (e.length == 5) total_spreadsheet += this.audit.statistics.spreadsheet_cats[laterality][e] 
			})

			return [ total_ia, total_spreadsheet ]

		},
		Percentage(first, second) {

			let val = Number.parseInt(second * 100 / first); 

			return Number.isNaN(val) ? 0 : val

		},
		SaveObs(e) {

			this.StartLoading()
			this.LoadingMessage('Saving observation...')

			window.api.call('post', 'audit-save-observation', {
				audit_id: 		this.audit.id,
				observation:	this.observation
			})
			.then(({data}) => {

				if (!data.response) {

					Swal.fire({
						text: 'Error on save observation!',
						icon: 'error',
						toast: true,
						position: 'top',
						timer: 5000,
						showConfirmButton: false
					})

					return 

				}

				Swal.fire({
					text: 'Observation saved!',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 2000,
					showConfirmButton: false
				})

			})
			.finally(() => {

				this.FinishLoading()

			})

		},
        GetAchadoStatisticSum(achado_key, cat_key, column) {

            // Alerta de Gambitech para somar os valores de lateralidades diferentes
            let statistics_r = this.audit.statistics.achados[cat_key][`right_${achado_key}`]['ia']
            let statistics_l = this.audit.statistics.achados[cat_key][`left_${achado_key}`]['ia']

            let statistics = { ...statistics_r }

            for (const statisticsLElement of Object.entries(statistics_l)) {
                statistics[statisticsLElement[0]] += statisticsLElement[1]
            }

            return statistics[column]

        }
	},
}
</script>

<style scoped>
.statistic-4rem {
    width: 4.5rem;
}
.right-divisor {
    background-color: var(--bs-table-bg);
    border-right-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

/* an table even smaller */
.table-sm > :not(caption) > * > * {
    padding: 0.06rem 0.10rem;
}
</style>