<template>
	<form @submit.prevent="Save" class="pb-3">
		<div class="row">
			<div class="col-12 col-md-8">
				<div class="mb-3">
					<label>Name</label>
					<input 
						type="text" 
						class="form-control"
						v-model="v$.form.name.$model"
						:class="{ 'is-invalid': v$.form.name.$error }"
					>
					<div class="invalid-feedback">
						Required
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>Type</label>
					<select
						v-model="form.type"
						class="form-select"
					>
						<option :value="1">HEADQUARTER</option>
						<option :value="2">BRANCH</option>
					</select>
				</div>
			</div>
			<transition name="fade" mode="out-in">
				<div class="col-12" v-if="form.type == 2">
					<div class="mb-3">
						<label>Headquarter</label>
						<select
							class="form-control"
							v-model="v$.form.parent_id.$model"
							:class="{ 'is-invalid': v$.form.parent_id.$error }"
						>
							<option value="">Select an organization...</option>
							<option
								v-for="hq in headquarters"
								:value="hq.id"
								:key="hq.id"
							>
								{{ hq.name.toString().toUpperCase() }}
							</option>
						</select>
						<div class="invalid-feedback">
							Required
						</div>
					</div>
				</div>
			</transition>
			<div class="col-12 col-md-6">
				<div class="mb-3">
					<label>Email</label>
					<input 
						type="email" 
						v-model="v$.form.email.$model"
						:class="{ 'is-invalid': v$.form.email.$error }"
						class="form-control"
					>
					<div class="invalid-feedback" v-if="v$.form.email.required.$invalid">
						Required
					</div>
					<div class="invalid-feedback" v-if="v$.form.email.email.$invalid">
						Must be an valid email
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="mb-3">
					<label>Phone</label>
					<input 
						type="tel" 
						v-model="v$.form.phone.$model"
						:class="{ 'is-invalid': v$.form.phone.$error }"
						class="form-control"
						v-mask="['+## (##) ####-####', '+## (##) #####-####']"
					>
					<div class="invalid-feedback">
						Required
					</div>
				</div>
			</div>
			<div class="col-12 text-right">
				<div class="mt-3">
					<button
						type="submit"
						class="btn btn-success px-5 text-white"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	
	import { mapGetters, mapActions } from 'vuex'
	import useVuelidate from '@vuelidate/core'
	import { required, requiredIf, email } from '@vuelidate/validators'
	export default {

		name: 'OrganizationForm',
		emits: {
			submit: {
				type: Object
			}
		},
		props: {
			organization: {
				type: 	Object,
				default: () => {}
			}
		},
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				form: {
					name: 		'',
					type:  		1,
					email: 		'',
					phone: 		'',
					parent_id: 	'',
				}
			}
		},
		validations()
		{
			return {
				form: {
					name: {
						required
					},
					email: {
						email,
						required
					},
					parent_id: {
						requiredIfBranch: requiredIf((value, vm) => vm.form.type !== 1)
					},
					phone: {
						required
					}
				}
			}
		},
		watch: {
			'form.type': {
				handler()
				{
					this.v$.form.$reset()
				}
			},
			organization: {
				handler(org)
				{
					if(!window._.isEmpty(org))
					{
						this.form.name 			= org.name
						this.form.type 			= org.type
						this.form.email 		= org.email
						this.form.phone 		= org.phone
						this.form.parent_id 	= org.parent_id
					}
				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			...mapGetters('auth', [
				'organizations'
			]),
			headquarters()
			{
				return this.organizations.filter(o => {
						return o.organization.type === 1 && (!window._.isEmpty(this.organization) ? o.organization.id !== this.organization.id : true)
					})
					.map( o => o.organization )
			}
		},
		methods: {
			Save()
			{
				this.v$.form.$touch()

				if(!this.v$.form.$invalid)
				{
					this.$emit('submit', this.form)
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>