
export default class Toast
{
	constructor (configurator) 
	{
		this.configurator 	= {
			enable_title: 		true,	
			timer: 				null,
			color: 				'light',
			position: 			'top-right',
			enable_close: 		true,

			...configurator
		}

		const container_id 	= 'toast-container'
		const e 			= document.createElement('div')
		e.id 				= container_id
		e.classList.add(`toast-position-${this.configurator.position}`)

		document.body.prepend(e)

		this.container 		= document.getElementById(container_id)
	}

	fire(obj)
	{
		const unique_id = this.generateUniqueID()

		const toast 	= document.createElement('div')
		
		toast.id 		= unique_id
		toast.classList.add('toast')
		toast.classList.add('fade')
		toast.classList.add('show')

		if(obj.color)
		{
			toast.classList.add(`bg-${obj.color ?? this.configurator.color}`)
		}

		if(this.configurator.enable_title)
		{
			const title 		= document.createElement('div')
			title.classList.add('toast-header')
			title.classList.add('text-dark')

			const span 			= document.createElement('span')
			span.innerHTML 		= obj.title ?? ''

			title.append(span)

			if(this.configurator.enable_close)
			{
				const btn 		= document.createElement('button')
				btn.classList.add('btn-close')
				btn.dataset.id  = unique_id

				btn.addEventListener('click', (e) => {

					toast.classList.remove('show')
					toast.classList.add('hide')

					this.container.removeChild(toast)
				})
				
				title.append(btn)
			}

			toast.append(title)
		}	

		const text 		= document.createElement('div')
		text.classList.add('toast-body')
		text.classList.add('text-white')
		text.innerHTML 	= obj.text ?? ''

		toast.append(text)

		this.container.prepend(toast)

		if(obj.timer || this.configurator.timer)
		{
			setTimeout(() => {
				
				this.removeToast(unique_id)

			}, (obj.timer ?? this.configurator.timer))
		}
	}

	generateUniqueID()
	{
		return `toast-${Date.now()}`
	}

	removeToast(unique_id)
	{
		const toast 		= document.getElementById(unique_id)
		toast.classList.remove('show')
		toast.classList.add('hide')

		this.container.removeChild(toast)
	}
}
