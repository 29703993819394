<template>
	<div>
		<template v-if="user.email">
			<p>
				If you want to replace your current e-mail "<b>{{user.email}} </b>", we will do a validation process for the new e-mail, enter it below to get started
			</p>
		</template>
		<template v-else>
			<p>
				Register an e-mail to login, receive platform notices and notifications, enter it below to get started.
			</p>
		</template>
		<div class="row align-items-end mt-5">
			<div class="col-md pr-md-0">
				<div class="form-group mb-md-0">
					<input 
						type="email"
						class="form-control"
						:class="{ 'is-invalid': v$.form.email.$error }"
						placeholder="New e-mail"
						v-model="v$.form.email.$model"
					>
					<div class="invalid-feedback" v-if="v$.form.email.required.$invalid">
						Required
					</div>
				</div>
			</div>
			<div class="col-md-auto">
				<button 
					type="button"
					@click="Send" 
					class="btn btn-success btn-block px-4 text-white"
				>	
					Send
					<i class="bi bi-box-arrow-in-up-right ms-2 font-16"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import useVuelidate from '@vuelidate/core'
	import { required } from '@vuelidate/validators'
	export default {

		name: 'Email',
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				form: 	{
					email: 	''
				}
			}
		},
		validations()
		{
			return {
				form: 	{
					email: 	{
						required
					}
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'user'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Send()
			{
				this.v$.form.$touch()

				if(!this.v$.form.$invalid)
				{
					this.StartLoading()

					window.api.call('post', '/send-mail-to-validate-my-new-email', this.form)
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
									icon: 	'success',
					        		title:  'Validation process started',
					        		text: 	'You will shortly receive a validation email to the address provided with instructions to proceed.'
								})

							}else{

								Swal.fire({
									icon: 	'error',
					        		title:  'Ops...',
					        		text: 	'An error has occurred on trying to send validation email, contact support.'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>