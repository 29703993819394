<template>
	<div class="row">
		<div class="col-12">
			<DataTables
				:headers="headers"
				v-model="items"
				:search="search"
			>
				<template v-slot:body="{ item }">
					<td>
						<div class="td-content">
							#{{item.id}}
						</div>
					</td>
					<td>
						<div class="td-content">
							{{item.accession_number}}
						</div>
					</td>
					<td>
						<div class="td-content">
							{{item.patient_name}}
						</div>
					</td>
					<td>
						<div class="td-content">
							<div 
								class="badge text-uppercase"
								:class="{
									'bg-danger': item.result_type == 'suspect',
									'bg-success': item.result_type == 'normal'
								}"
							>
								{{item.result_type}}
							</div>
						</div>
					</td>
					<td>
						<div class="td-content">
							{{item.created_at}}
						</div>
					</td>
					<td>
						<div class="td-content justify-content-end">
							<button 
								type="button" 
								class="btn btn-tertiary btn-sm px-5"
								@click="ShowResult(item)"
							>
								show <i class="bi bi-search ms-2"></i>
							</button>
						</div>
					</td>
				</template>
			</DataTables>
		</div>
		<div 
			class="modal fade" 
			id="result-modal" 
			tabindex="-1" 
			aria-labelledby="result-modal" 
			aria-hidden="true"
		>
			<div class="modal-dialog modal-xl" v-if="study">
			    <div class="modal-content">
			    	<div class="modal-header">
			    		<h5 class="modal-title">
							Study #<b>{{ study.id }}</b>
						</h5>
			    	</div>
			        <div class="modal-body">
						<div class="row">
							<div class="col-12 mb-4">
								<div class="row">
									<div class="col-12 max-200">
										ACCESSION NUMBER
									</div>
									<div class="col text-dark">
										<b>{{ study.accession_number }}</b>
									</div>
								</div>
								<div class="row">
									<div class="col-12 max-200">
										PATIENT
									</div>
									<div class="col text-dark">
										<b>{{ study.patient_name }}</b>
									</div>
								</div>
								<div class="row">
									<div class="col-12 max-200">
										RESULT
									</div>
									<div class="col text-dark">										
										<div 
											class="badge text-uppercase font-16"
											:class="{
												'bg-danger': study.result_type == 'suspect',
												'bg-success': study.result_type == 'normal'
											}"
										>
											{{study.result_type}}
										</div>
									</div>
								</div>
								<template v-if="study.result_type == 'suspect'">
									<div class="row">
										<div class="col-12 max-200">
											SUSPECT QUADRANTS
										</div>
										<div class="col text-dark">
											<div class="row">
												<template v-if="study.quadrants.left && study.quadrants.left.length > 0">

													<div 
														class="col-12"
														v-for="(quadrant, index) in study.quadrants.left"
														:key="index"
													>
														LEFT: <b>{{ quadrant.quadrant }}</b> | TYPE: <b>{{ quadrant.type }}</b> | SUBTYPE <b>{{ quadrant.subtype }}</b>
													</div>
												</template>
												<template v-if="study.quadrants.right && study.quadrants.right.length > 0">
													<div 
														class="col-12"
														v-for="(quadrant, index) in study.quadrants.right"
														:key="index"
													>
														RIGHT: <b>{{ quadrant.quadrant }}</b> | TYPE: <b>{{ quadrant.type }}</b> | SUBTYPE <b>{{ quadrant.subtype }}</b>
													</div>
												</template>
											</div>
										</div>
									</div>
								</template>
							</div>
							<div class="col-12">
								<template v-if="study.heatmaps && study.heatmaps.length > 0">
									<div class="row">
										<div class="col-12">
											<h5>Heatmaps</h5>
										</div>
										<div 
											class="col-auto"
											v-for="heatmap in study.heatmaps"
											:key="heatmap"
										>
											<div 
												class="heatmap-container"
												@click="ZoomImage(heatmap)"
											>
												<img :src="GetImageLink(heatmap, 'thumb')">
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>			
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Studies',
		props: {
			studies: {
				type: 		Array,
				default: 	() => []
			}
		},
		data () {
			return {
				search: 		'',
				headers: 		[
					{ text: 'Our ID', value: 'id', sortable: true, type: Number },
					{ text: 'Accession number', value: 'accession_number', sortable: true, type: Number },
					{ text: 'Patient', value: 'patient_name', sortable: true, type: String },
					{ text: 'Type', value: 'result_type', sortable: true, type: String },
					{ text: 'Uploaded at', value: 'created_at', sortable: true, type: Date },
					{ text: '', value: null, sortable: false },
				],
				result_modal: 	null,
				study: 			{},
				items: 			[]
			}
		},
		watch: {
			studies: {
				handler(studies)
				{
					this.items 		= studies
				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id', 'token'
			])
		},
		methods: {
			ShowResult(item)
			{
				this.study 		= item

				this.result_modal.show()
			},
			ZoomImage(image)
			{
				Swal.fire({
					imageUrl: this.GetImageLink(image),
					showConfirmButton: false,
					imageWidth: 720,
					imageHeight: 720,
					imageAlt: image,
				})
			},
			GetImageLink(image, mode = null)
			{
				let size 	= ''

				switch(mode)
				{
					case 'thumb':
						size 		= '&width=100'
					break
					default: 

					break
				}

				let url  = process.env.VUE_APP_API_URL
				url 	+= `/api/app/get-image/${this.organization_selected_id}/${image}`
				url 	+= `?token=${this.token}${size}`

				return url
			},
		},
		mounted()
		{
			this.result_modal 		= new window.bootstrap.Modal(document.getElementById('result-modal'))
		}
	}
</script>

<style lang="css" scoped>
</style>