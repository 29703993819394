const running_requests 	= (state) => {
	return state.running_requests
}

const loading_message	= (state) => {
	return state.loading_message
}

const updated_audit 	= (state) => {
	return state.updated_audit
}

const updated_study 	= (state) => {
	return state.updated_study
}

const menu_icons 		= (state) => {
	return state.menu_icons
}

const updated_file_upload	= (state) => {
	return state.updated_file_upload
}

export {
	running_requests,
	loading_message,
	updated_audit,
	updated_study,
	menu_icons,
	updated_file_upload
}