<template>
	<p>awaiting...</p>
</template>

<script>
	export default {

		name: 'GoBack',

		data () {
			return {

			}
		},
		beforeMount()
		{
			window.location.href = process.env.VUE_APP_LANDING_PAGE_URL
		}
	}
</script>

<style lang="css" scoped>
</style>