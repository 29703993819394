import { createRouter, createWebHistory } from 'vue-router'
import Vuex from './vuex'
import NProgress from 'nprogress'

import Swal from 'sweetalert2'

NProgress.configure({
    showSpinner: false
});

const router = createRouter({
    history:   createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            redirect: {
                name: 'Home'
            }
        },
        {
            name: 'GoBack',
            path: '/auth/401/redirect',
            component: require('./components/layout/GoBack.vue').default,
            meta: {
                title: 'await...'
            }
        },
        {
            name:   'Authorization',
            path:   '/auth/token/:token',
            props: true,
            component: require('./components/authorization/Index.vue').default,
            meta: {
                title: 'authorizing...'
            }
        },
        {
            path: '/',
            component: require('./components/layout/Index.vue').default,
            children: [
                {
                    path: '/',
                    component: require('./components/layout/EmptyParent.vue').default,
                    children: [
                        {
                            name: 'Home',
                            path: 'home',
                            component: require('./components/home/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title: 'Home'
                            }
                        },
                        {
                            name: 'Profile',
                            path: 'profile',
                            component: require('./components/profile/Index.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title: 'Profile'
                            }
                        },
                        {
                            name: 'Upload',
                            path: 'upload',
                            component: require('./components/upload/Index.vue').default,
                            meta: {
                                middlewareAuth:     true,
                                title:              'Upload queue',
                                role_area:          'upload.area'
                            }
                        },
                        {
                            name: 'AIClassifications',
                            path: 'ai-classifications',
                            component: require('./components/ai-classifications/Index.vue').default,
                            meta: {
                                middlewareAuth:     true,
                                title:              'AI Classifications in progress',
                                role_area:          'ai.classifications.area'
                            }
                        },
                        {
                            name: 'Results',
                            path: 'results',
                            component: require('./components/results/Index.vue').default,
                            meta: {
                                middlewareAuth:     true,
                                title:              'Results',
                                role_area:          'results.area'
                            }
                        },
                        {
                            name: 'Audit',
                            path: 'audits',
                            component: require('./components/audits/Index.vue').default,
                            meta: {
                                middlewareAuth:     true,
                                title:              'Audit',
                                role_area:          'audit.area'
                            }
                        },
                        {
                            path: 'patients',
                            component: require('./components/layout/EmptyParent.vue').default,
                            children: [
                                {
                                    name: 'Patients',
                                    path: '',
                                    component: require('./components/patients/Index.vue').default,
                                    meta: {
                                        middlewareAuth:     true,
                                        title:              'Patients',
                                        role_area:          'patients.area'
                                    }
                                },
                                {
                                    name: 'PatientProfile',
                                    path: ':user_id/profile',
                                    props: true,
                                    component: require('./components/patients/Profile.vue').default,
                                    meta: {
                                        middlewareAuth:     true,
                                        title:              'Patient Profile',
                                        //role_area:          'patients.profile.area'
                                    }
                                },
                            ]
                        },
                        {
                            path: 'organizations',
                            component: require('./components/layout/EmptyParent.vue').default,
                            children: [
                                {
                                    name: 'Organizations',
                                    path: '',
                                    component: require('./components/organizations/Index.vue').default,
                                    meta: {
                                        middlewareAuth: true,
                                        title: 'Organizations'
                                    }
                                },
                                {
                                    name: 'OrganizationProfile',
                                    path: ':organization_id/profile',
                                    props: true,
                                    component: require('./components/organizations/Profile.vue').default,
                                    meta: {
                                        middlewareAuth: true,
                                        title: 'Organization Profile'
                                    }
                                },
                                {
                                    name: 'CreateOrganization',
                                    path: 'create-organization',
                                    component: require('./components/organizations/CreateOrganization.vue').default,
                                    meta: {
                                        middlewareAuth: true,
                                        title: 'Create Organization'
                                    }
                                },
                                {
                                    name: 'CreateUser',
                                    path: ':organization_id/create-user',
                                    props: true,
                                    component: require('./components/organizations/CreateUser.vue').default,
                                    meta: {
                                        middlewareAuth: true,
                                        title: 'Create User'
                                    }
                                }
                            ]
                        },
                        {
                            path: 'equipments',
                            component: require('./components/layout/EmptyParent.vue').default,
                            children: [
                                {
                                    name: 'Equipments',
                                    path: '',
                                    component: require('./components/equipments/Index.vue').default,
                                    meta: {
                                        middlewareAuth:     true,
                                        title:              'Equipments',
                                        role_area:          'equipments.area'
                                    }
                                },
                                {
                                    name: 'EquipmentProfile',
                                    path: ':equipment_id/profile',
                                    component: require('./components/equipments/Profile.vue').default,
                                    meta: {
                                        middlewareAuth:     true,
                                        title:              'Equipment Profile',
                                        //role_area:          'equipment.profile.area'
                                    }
                                }
                            ]
                        },
                        {
                            path: '',
                            redirect: {
                                name: 'Home'
                            }
                        }
                    ]
                },
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.middlewareAuth))
    {
        NProgress.start()

        if (!Vuex.getters['auth/check'])
        {
            window.location.href = process.env.VUE_APP_LANDING_PAGE_URL

            return;
        }

        if(to.meta.role_area)
        {
            const roles         = Vuex.getters['auth/roles']

            if(roles.indexOf(to.meta.role_area) === -1)
            {
                Swal.fire({
                    icon:       'error',
                    toast:      true,
                    position:   'top',
                    showConfirmButton: false,
                    title:      'You dont have permission.',
                    timer:      3000
                })

                next('/home')

                return;
            }
        }
    }

    next()
})

router.afterEach((to) => {
    document.title = 'ERVISION | ' + to.meta.title

    NProgress.done()
})

export default router;