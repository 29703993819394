<template>
	<div class="container">
        <div class="row justify-content-center pb-3">
            <div class="col-md-7">
                <a href="javascript:;" class="btn btn-tertiary px-4" @click="$router.back()">
					<i class="bi bi-arrow-left me-2"></i> Back
				</a>
            </div>
            <div class="col-md-7">
                <div class="row justify-content-md-between justify-content-end align-items-center pt-2 mb-2 flex-nowrap">
					<div class="col">
						<div class="mb-2 text-center">
							<span>Organizations</span>
							<h4 class="font-24 font-md-32 m-0">Create organization</h4>
						</div>
					</div>
				</div>
				<transition name="fade" mode="out-in">
					<div v-if="step == 1" key="1">
						<div class="row mt-4">
							<div class="col-12">
								<p class="font-20 text-center my-3">Here you can create headquarter or branch organizations to manage your users, permissions, studies and finance.</p>
							</div>
							<div class="col-12 text-right mt-4">
								<button
									type="button"
									class="btn btn-tertiary px-5"
									@click="step = 2"
								>
									Continue
									<i class="bi bi-arrow-right ms-2"></i>
								</button>
							</div>
						</div>
					</div>
					<div v-else-if="step == 2" key="2">
						<OrganizationForm 
							@submit="SaveOrganization"
						/>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import OrganizationForm from './components/OrganizationForm'
	export default {

		name: 'CreateOrganization',
		components: {
			OrganizationForm
		},
		data () {
			return {
				step: 		1
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthUser'
			]),
			SaveOrganization(form)
			{
				this.StartLoading()

				window.api.call('post', '/save-organization', form)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 	'success',
								title: 	'Organization successfully created !',
								text: 	'Now, you can manage this organization.'
							})
							.then(() => {

								this.$router.push({ name: 'OrganizationProfile', params: { 
										organization_id: data.organization_id 
									}
								})
							})

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Oopss...',
								text: 	'An error has occurred on trying create this organization, contact support.'
							})
						}
					})
					.finally(() => {

						this.GetAuthUser()

						this.FinishLoading()
					})
			}
		}
	}
</script>

<style lang="css" scoped>
</style>