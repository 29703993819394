const UPDATE_USER 		= (state, payload) => {
	state.user				= payload ? {...payload} : {}
}

const UPDATE_ORGANIZATIONS 	= (state, payload) => {
	state.organizations			= payload ? [...payload] : []
}

const UPDATE_ROLES 			= (state, payload) => {
	state.roles	 				= payload ? {...payload} : {}
}

const UPDATE_TOKEN 			= (state, payload) => {
	state.token 				= payload
}

const UPDATE_ORGANIZATION_SELECTED = (state, payload) => {
	state.organization_selected_id = payload
}

const ADD_ONLINE_ACCOUNT 	= (state, payload) => {
	state.online_accounts.push(payload)
}

const REM_ONLINE_ACCOUNT 	= (state, payload) => {
	state.online_accounts 		= state.online_accounts.filter( o => o.id !== payload.id )
}

const RESET_ONLINE_ACCOUNT 	= (state, payload) => {
	state.online_accounts 		= payload ? [...payload] : []
}


export {
	UPDATE_USER,
	UPDATE_TOKEN,
	UPDATE_ROLES,
	UPDATE_ORGANIZATIONS,
	UPDATE_ORGANIZATION_SELECTED,
	ADD_ONLINE_ACCOUNT,
	REM_ONLINE_ACCOUNT,
	RESET_ONLINE_ACCOUNT
}