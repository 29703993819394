<template>
	<div>
		<a
			class="btn btn-tertiary btn-lg"
			href="javascript:;"
			@click="Open"
		 	style="height: 59px;"
		>
			<div class="row align-items-center justify-content-center px-3">
				<div class="col-auto pe-1">
					<i class="bi bi-building"></i>
				</div>
				
				<div class="col text-left w-100 mt-n1 mb-1 ps-2">
                    <span class="font-14 text-white fw-light">
                        organization selected:
                    </span>
                    <h4 
                    	class="font-16 m-0 text-uppercase overflow-text text-white"
                    	v-if="organization_selected_object"
                    >
                        {{ organization_selected_object.organization.name }}
                    </h4>
                </div>
			</div>
		</a>
	
		<div 
			class="modal fade" 
			id="select-organization" 
			tabindex="-1" 
			data-bs-backdrop="static" 
			data-bs-keyboard="false"
			aria-labelledby="select-organization" 
			aria-hidden="true"
		>
			<div class="modal-dialog stop">
			    <div class="modal-content rounded">
			        <div class="modal-body">
			        	<div class="py-4 px-3">
							<div class="mb-4 text-center">
								<h3 class="font-28">Select an organization</h3>
							</div>
							<form class="row justify-content-between">
								<div 
									class="col-12"
									v-for="(link, index) in organizations"
									:key="index"
								>
									<div 
										class="select-card select-tertiary mt-2"
										:class="{'active': link.selected }"
										@click="SelectOrganization(link)"
									>
										<span class="text-uppercase">{{ link.organization.name }}</span>
										<i class="bi bi-chevron-right"></i>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Organizations',
		props: 	{
			open: 	{
				type: 		Boolean,
				default: 	false
			}
		},
		data () {
			return {
				modal: 		 null
			}
		},
		computed: {
			...mapGetters('auth', [
				'user', 'organizations', 'organization_selected_id', 'organization_selected_object'
			])
		},
		watch: {
			'organization_selected_object': {
				handler(val)
				{
					if(this.organizations.length > 1 && !val)
					{
						if(val)
						{
							this.Open()

						}
					}
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('auth', [
				'SetOrganizationSelected', 'GetAuthUser'
			]),
			...mapActions('notification', [
				'GetMyNotifications'
			]),
			Open()
			{
				this.modal.show()
			},
			SelectOrganization(organization)
			{
				this.SetOrganizationSelected(organization)

				this.$emit('change-organization')

				this.Close()
			},
			Close()
			{
				this.modal.hide()
			}
		},
		async mounted()
		{
			const modal	= document.getElementById('select-organization')
			this.modal 	= new window.bootstrap.Modal(modal)

			if(!this.organization_selected_id)
			{
				if(this.organizations.length === 1)
				{
					await this.SetOrganizationSelected(this.organizations[0])

				}else if(this.organizations.length > 1)
				{
					this.Open()				
				}
					
			}else{

				window.Echo.RunOrganization()

				this.GetMyNotifications()
			}

			
		}
	}
</script>

<style lang="css" scoped>


</style>