const StartLoading = ({ commit }) => {
	commit('ADD_RUNNING_REQUEST')
}

const FinishLoading = ({ commit, state }) => {
	commit('REM_RUNNING_REQUEST') 
	if (state.running_requests <= 0) state.loading_message = null 
}

const LoadingMessage  = ({ commit, state }, payload) => {
	state.loading_message = payload 
}

const UpdatedAudit  = ({ commit, state }, payload ) => {
	state.updated_audit = payload;
	commit('UPDATED_AUDIT', payload)
}   

const StudyUpdateEvent = ({commit, state}, payload ) => { 
	state.updated_study = payload 
}

const UpdatedFileUpload = ({commit, state}, payload) => {
	state.updated_file_upload = payload
}

export {
	StartLoading,
	FinishLoading,
	LoadingMessage,
	UpdatedAudit,
	StudyUpdateEvent,
	UpdatedFileUpload
}