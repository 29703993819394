<template>
    <div class="container create-user">
        <div class="row justify-content-center">
            <div class="col-md-7">
                <a href="javascript:;" class="btn btn-tertiary px-4" @click="$router.back()">
					<i class="bi bi-arrow-left me-2"></i> Back
				</a>
            </div>
            <div class="col-md-7">
                <div class="row justify-content-md-between justify-content-end align-items-center pt-2 mb-2 flex-nowrap">
					<div class="col">
						<div class="mb-2 text-center">
							<span>Organization</span>
							<h4 class="font-24 font-md-32 m-0">Create new user</h4>
						</div>
					</div>
				</div>
            </div>
            <div class="col-md-7 mt-2">
                <UserForm @submit="SaveUser" :email="email"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex' 
    import UserForm from './components/UserForm'
    export default {
        name: 'CreateUser',
        components: {
            UserForm
        },
        props: {
            organization_id: {
                type:       [String, Number],
                required:   true,
                default:    null
            },
            email:  {
                type:       String,
                default:     null
            }
        },
        data() {
            return {

            }
        },
        methods: {
            ...mapActions('system', [
                'StartLoading', 'FinishLoading'
            ]),
            InviteUser(email)
            {
                const vm        = this

                vm.StartLoading()

                return window.api.call('post', '/invite-organization-user', {
                        organization_id:    vm.organization_id,
                        email:              email
                    })
                    .then(({data}) => {
                        if(data.response)
                        {
                            Swal.fire({
                                icon:   'success',
                                title:  'User successfully invited !',
                                text:   'Now, ask him to accept the invitation sent in his notifications.'
                            })
                            .then(() => {
                                vm.$router.back()
                            })

                        }else{

                            Swal.fire({
                                icon:   'error',
                                title:  'Ouch !',
                                text:   'An error has occurred on trying invite this user, contact support.'
                            })
                        }
                    })
                    .finally(() => {
                        vm.FinishLoading()
                    })
            },
            SaveUser(user)
            {
                const vm    = this
                vm.StartLoading()

                return window.api.call('post', '/save-organization-user', {
                        ...user,
                        organization_id: vm.organization_id
                    })
                    .then(({data}) => {
                        if(data.response)
                        {
                            Swal.fire({
                                icon:   'success',
                                title:  'User successfully created !',
                                text:   'Now, configure permissions for this user and request email validation.'
                            })
                            .then(() => {
                                vm.$router.back()
                            })

                        }else if(data.message == "email_exists")
                        {
                            Swal.fire({
                                icon:   'info',
                                title:  'This user already exists',
                                text:   data.user.name + ' was found using this email, lets invite him to join this organization?.',
                                showDenyButton: true,
                                confirmButtonText: 'Yes',
                                denyButtonText: 'No'
                            })
                            .then((result) => {
                                if(result.isConfirmed)
                                {
                                    vm.InviteUser(data.user.email)
                                }
                            })

                        }else{

                            Swal.fire({
                                icon:   'error',
                                title:  'Ouch !',
                                text:   'An error has occurred on trying create this user, contact support.'
                            })
                        }
                        
                    })
                    .finally(() => {
                        vm.FinishLoading()
                    })
            }
        }
    }
</script>

<style>

</style>