<template>
	<form 
		class="row justify-content-end" 
		@submit.prevent="SavePersonalInfo"
	>
		<div class="col-12">
			<div class="form-group">
				<label>Name</label>
				<input 
					type="text" 
					v-model="v$.form.name.$model"
					class="form-control"
					:class="{ 'is-invalid': v$.form.name.$error }"
					ref="name"
				>
				<div class="invalid-feedback">
					Required
				</div>
			</div>
		</div>
		<div class="col-12 mt-3">
			<div class="form-group">
				<label>Phone</label>
				<input 
					type="tel" 
					v-model="form.phone"
					class="form-control"
					v-mask="['+## (##) #####-####', '+## (##) ####-####']" 
				>
			</div>
		</div>
		<div class="col-12 col-md-auto mt-3">
			<button 
				type="submit" 
				class="btn btn-success text-white btn-block px-5"
			>
				Save
			</button>
		</div>
	</form>	
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import useVuelidate from '@vuelidate/core'
	import { required, email } from '@vuelidate/validators'
	export default {

		name: 'PersonalInfo',
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				form: 	{
					name: 	'',
					phone:	''
				}
			}
		},
		validations() 
		{
			return {
				form: 	{
					name: 		{
						required
					}
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'user'
			])
		},
		watch: {
			user: {
				handler(val)
				{
					this.form.name 				= val.name
					this.form.phone 			= val.phone
				},
				immediate:  true,
				deep: 		true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthUser'
			]),
			async SavePersonalInfo()
			{
				this.v$.form.$touch()

				if(!this.v$.form.$invalid)
				{
					this.StartLoading()

					window.api.call('post', '/save-personal-info', this.form)
						.then(({data}) => {
							if(data.response)
							{
								this.GetAuthUser()

								Swal.fire({
									toast:  	true,
									title:		'Personal info successfully saved.',
									icon: 		'success',
									timer: 		3000,
									position: 	'top',
									timerProgressBar: true,
									showConfirmButton: false
								})

							}else{

								Swal.fire({
									icon: 	'error',
					        		title:  'Oops...',
					        		text: 	'An error has occurred, contact support.'
								})
								
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			}
		}
	}
</script>

<style lang="css" scoped>

</style>