<template>
    <div>
        <div class="row align-items-start">

            <div class="col-12 col-md-auto">
                <div class="input-group">
                    <input 
                        type="text" 
                        class="form-control"
                        placeholder="User email"
                        :class="{ 'is-invalid': v$.email_to_invite.$error }"
                        v-model="v$.email_to_invite.$model"
                    >
                    <button 
                        type="button" 
                        class="btn btn-tertiary px-4"
                        @click="InviteUser"
                    >
                        Invite
                    </button>             
                </div>
                
            </div>
            <div class="col-12 col-md-auto my-2 my-md-0 text-center align-self-center">
                <b>Or</b>
            </div>
             <div class="col-12 col-md text-center text-md-left">
                <router-link
                    type="button"
                    class="btn btn-success text-white px-5"
                    :to="{ name: 'CreateUser', params: { organization_id: organization_id } }"
                >
                    Create    
                </router-link>
            </div>
            <div class="col-12 col-md-4 mt-3 mt-md-0">
                <div class="input-group">
                    <span class="input-group-text" id="search-users">
                        <i class="bi bi-search"></i>
                    </span>
                    <input 
                        type="search" 
                        class="form-control" 
                        placeholder="Search" 
                        aria-label="Search" 
                        aria-describedby="search-users"
                        v-model="search"
                    >
                </div>
            </div>
            <div class="col-12">
                <DataTables
                    :headers="headers"
                    v-model="filtered_users"
                    :search="search"
                    :paginate="true"
                    :items_per_page='5'
                >
                    <template v-slot:body="{ item }">
                        <td>
                            <div class="td-content">
                                #<b>{{ item.id }}</b>
                            </div>
                        </td>
                        <td>
                            <div class="td-content">
                                <b>{{ item.name }}</b>
                            </div>
                        </td>
                        <td>
                            <div class="td-content">
                                {{ item.accepted_at ? ParseDate(item.accepted_at) : 'waiting for acceptance' }}
                            </div>
                        </td>
                        <td>
                            <div class="td-content justify-content-end">
                                <button
                                    type="button"
                                    class="btn btn-tertiary btn-sm me-2 px-4"
                                    @click="OpenRoleModal(item)"
                                >
                                    permissions ({{ item.roles.length }})
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm px-3"
                                    :disabled="links.length === 1"
                                >
                                    unlink
                                </button>
                            </div>
                        </td>
                    </template>
                </DataTables>
            </div>
            <div 
                class="modal fade" 
                id="roles-modal" 
                tabindex="-1" 
                aria-labelledby="roles-modal" 
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Configure user roles</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <p class="text-center font-20">
                                        <b>Select below the roles this user "{{ selected_user ? selected_user.name : '' }}" can use:</b>
                                    </p>
                                </div>
                                <template v-if="roles.length > 0">
                                    <div 
                                        class="col-12"
                                        v-for="role in roles"
                                        :key="role.id"
                                    >
                                        <div class="form-check">
                                            <input 
                                                type="checkbox" 
                                                class="form-check-input"
                                                :id="`role-${role.id}`"
                                                :value="role.id"
                                                v-model="selected_roles"
                                            >
                                            <label :for="`role-${role.id}`" class="ps-1 form-check-label">
                                                {{ role.name }}
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-12">
                                        <p>Roles not found, contact support.</p>
                                    </div>
                                </template>                            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="d-flex justify-content-end">
                                <button 
                                    type="button" 
                                    class="btn btn-success text-white px-5"
                                    @click="SaveUserRoles"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import { mapActions, mapGetters } from 'vuex' 
    import useVuelidate from '@vuelidate/core'
	import { required, email } from '@vuelidate/validators'
    export default {
        name: 'Users',
        emits: [
            'refresh'
        ],
        setup () 
		{
		    return { v$: useVuelidate() }
		},
		props: {
			links: {
				type:       Array,
				default:    () => []
			},
            organization_id: {
                type:       [String, Number],
                required:   true
            }
		},
		data () {
			return {
                roles:      [],
                headers:    [
                    { text: 'ID', value: 'id', sortable: true, type: Number },
					{ text: 'Name', value: 'name', sortable: true, type: String },
                    { text: 'Accepted at', value: 'accepted_at', sortable: true, type: Date },
                    { text: '', value: null, sortable: false }
                ],
                modal:      null,
                selected_roles:     [],
                selected_user:      null,
                search:             '',
                email_to_invite:    ''
			}
		},
        computed: {
            filtered_users()
            {
                return this.links.map(link => {
                    return {
                        ...link.user,
                        accepted_at:    link.accepted_at,
                        roles:          link.roles,
                        extra:          link.extra
                    }
                })
            }
        },
        validations()
        {
            return {
                email_to_invite: {
                    email,
                    required
                }
            }
        },
        methods: {
            ...mapActions('system', [
                'StartLoading', 'FinishLoading'
            ]),
            InviteUser()
            {
                const vm        = this

                vm.v$.email_to_invite.$touch()

                if(!vm.v$.email_to_invite.$invalid)
                {
                    vm.StartLoading()

                    return window.api.call('post', '/invite-organization-user', {
                            organization_id:    vm.organization_id,
                            email:              vm.email_to_invite
                        })
                        .then(({data}) => {
                            if(data.response)
                            {
                                Swal.fire({
                                    icon:   'success',
                                    title:  'User successfully invited !',
                                    text:   'Now, ask him to accept the invitation sent in his notifications.'
                                })

                            }else if(data.message == 'user_not_found')
                            {
                                Swal.fire({
                                    icon:   'info',
                                    title:  'User not exists',
                                    text:   'This email is not linked to any user on our platform, you can create one !',
                                    showCancelButton: true,
                                    confirmButtonText: 'Yes'
                                })
                                .then((result) => {
                                    if(result.isConfirmed)
                                    {
                                        vm.$router.push({ name: 'CreateUser', params: { organization_id: vm.organization_id, email: vm.email_to_invite }})
                                    }
                                })

                            }else{

                                Swal.fire({
                                    icon:   'error',
                                    title:  'Ouch !',
                                    text:   'An error has occurred on trying invite this user, contact support.'
                                })
                            }
                        })
                        .finally(() => {
                            vm.FinishLoading()
                        })
                }
            },
            GetAllRoles()
            {
                this.StartLoading()

                return window.api.call('get', '/get-all-roles')
                    .then(({data}) => {
                        if(data.response)
                        {
                            this.roles      = data.roles
                        }
                    })
                    .finally(() => {
                        this.FinishLoading()
                    })
            },
            SaveUserRoles()
            {
                this.StartLoading()

                return window.api.call('post', '/save-user-roles', {
                        user_id:            this.selected_user.id,
                        roles:              this.selected_roles,
                        organization_id:    this.organization_id
                    })
                    .then(({data}) => {
                        if(data.response)
                        {
                            Swal.fire({
                                toast:      true,
                                position:   'top',
                                timer:      3000,
                                title:      'User roles successfully saved',
                                icon:       'success',
                                showConfirmButton: false
                            })
                        }else{

                            Swal.fire({
                                icon:       'error',
                                title:      'Eww!',
                                text:       'A error has occurred on trying save user roles, contact support.'
                            })
                        }
                    })
                    .finally(() => {
                        this.selected_roles         = []
                        this.selected_user          = null

                        this.$emit('refresh')

                        this.modal.hide()
                            
                        this.FinishLoading()
                    })
            },
            OpenRoleModal(user)
            {
                this.selected_roles     = user.roles.map( r => r.id )
                this.selected_user      = user

                this.modal.show()
            },
            ParseDate(date)
            {
                return window.helpers.ParseDate(date)
            }
        },
        mounted()
        {
            this.modal      = new window.bootstrap.Modal(document.getElementById('roles-modal'))
        },
        beforeMount()
        {
            this.GetAllRoles()
        }
    }
</script>

<style>

</style>