<template>
	<transition name="fast-fade" mode="out-in">
	    <div class="overlay-loading" v-if="loading">
	    	<div class="overlay-container">
		    	<div class="row">
		    		<div class="col-12">
		    			<p class="m-0">Awaiting...</p>
						<p v-if="loading_message" class="m-0">
							{{ loading_message }}
						</p>
		    		</div>
		    		<div class="col-12 mt-3">
				    	<div class="spinner-border" role="status">
						  	<span class="sr-only d-none">Awaiting...</span>
						</div>
					</div>
				</div>
			</div>
	    </div>
	</transition>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {

		name: 'Loading',

		data () {
			return {
				loading: 	 false
			}
		},
		computed: {
			...mapGetters('system', [
				'running_requests', 'loading_message'
			]),
		},
		watch: {
			running_requests(val)
			{
				this.loading 		= val > 0
			}
		}
	}
</script>

<style lang="css" scoped>
</style>