const GetMyNotifications = ({ commit, rootState, dispatch }) => {

	dispatch('system/StartLoading', null,{
			root: 		true
		})

	return window.api.call('post', '/get-my-notifications', {
			organization_id:  	parseInt(rootState.auth.organization_selected_id)
		})
		.then(({data}) => {
			if(data.response)
			{
				commit('UPDATE_ALL', data.notifications)
			}
		})
		.finally(() => {

			dispatch('system/FinishLoading', null,{
					root: 		true
				})
			
		})
}

const BroadcastedNotification = ({ commit, state }, payload ) => {

	let notifications 	= state.notifications
	notifications.push(payload) 

	window.toast.fire({
		color: 	'tertiary',
		timer: 	4000,
		title: 	payload.broadcast_content.title,
		text: 	payload.broadcast_content.message
	})

	window.bnotification.fire({
		title: 	payload.broadcast_content.title,
		text: 	payload.broadcast_content.message
	})

	commit('UPDATE_ALL', notifications)
}

export {
	GetMyNotifications,
	BroadcastedNotification
}