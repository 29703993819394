<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-block mx-auto mb-4 pb-md-2">
					<span>Patient</span>
					<h4 class="m-0 font-24 font-md-32">All Patients</h4>
				</div>
			</div>
		</div>	
		<div class="col-12 offset-md-8 col-md-4 mb-2">
			<div class="input-group">
				<span class="input-group-text" id="search-studies">
					<i class="bi bi-search"></i>
				</span> 
				<input 
					type="search" 
					class="form-control" 
					v-model="search" 
					placeholder="Search" 
					aria-label="Search" 
					aria-describedby="search-studies"
				>
			</div>
		</div>
		<div class="col-12">
			<DataTables
				:headers="headers"
				v-model="patients"
				:search="search"
				:paginate="true"
				:items_per_page='5'
			>
				<template v-slot:body="{ item }">
					<td>
						<div class="td-content">
							#<b>{{ item.id }}</b>
						</div>
					</td>
					<td>
						<div class="td-content">
							<b>{{ item.name }}</b>
						</div>
					</td>
					<td>
						<div class="td-content">
							{{ item.studies_count }}
						</div>
					</td>
					<td>
						<div class="td-content justify-content-end">
							<router-link
								:to="{ name: 'PatientProfile', params: { user_id: item.id }}"
								class="btn btn-tertiary btn-sm px-5 me-3"
							>
								show
								<i class="bi bi-search ms-2"></i>
							</router-link>
						</div>
					</td>
				</template>
			</DataTables>
		</div>	
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Index',

		data () {
			return {
				headers: 	[
					{ text: 'ID', value: 'id', sortable: true, type: Number },
                  	{ text: 'Name', value: 'name', sortable: true, type: String },
                  	{ text: 'Studies', value: 'studies_count', sortable: true, type: Number },
                  	{ text: '', value: null, sortable: false }
				],
				search: 	'',
				patients: 	[]
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetPatients()
			{
				this.StartLoading()

				window.api.call('post', '/get-patients', {
						organization_id: this.organization_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.patients 	= data.patients

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Oops...',
								text: 	'An error has occurred on get patients, contact support.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			}
		},
		beforeMount()
		{
			this.GetPatients()
		}
	}
</script>

<style lang="css" scoped>
</style>