<template>
	<div class="container organization-profile">
		<div class="row justify-content-center">
			<div class="col-12 mb-3">
				<router-link :to="{ name: 'Organizations' }" class="btn btn-tertiary px-4">
					<i class="bi bi-arrow-left me-2"></i> Back
				</router-link>
			</div>
			<div class="col-12" v-if="organization">
				<div class="row my-4">
					<div class="col-12 col-md-auto text-center">
						<div class="d-flex w-100 justify-content-center mb-3 mb-md-0">

							<div class="avatar">
								{{ organization.name.toUpperCase().substring(-1, 1) }}
							</div>
						</div>
					</div>
					<div class="col">
						<span>Organization</span>
						<h4 class="font-24 font-md-32 mb-4">
							{{ organization.name.toUpperCase() }}
						</h4>						
						<div class="row">
							<div class="col-12 max-150"> CREATED AT </div>
							<div class="col text-dark">
								<b>{{ organization.created_at }}</b>
							</div>
						</div>
						<div class="row">
							<div class="col-12 max-150"> STATUS </div>
							<div class="col text-dark">
								<div 
									class="badge font-16"
									:class="{
										'bg-success': organization.status,
										'bg-danger': !organization.status
									}"
								>
									{{ organization.status ? 'active' : 'inactive' }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="nav-wrapper position-relative mb-2">
							<ul class="nav nav-pills nav-fill flex-column flex-md-row" role="tablist" id="personal-tab">
								<li class="nav-item" role="presentation">
									<a 
										class="nav-link mb-sm-3 mb-md-0 active" 
										data-bs-toggle="tab" 
										href="#personal-info"
										id="personal-info-tab"
									>
										Personal Info
									</a>
								</li>
								<li class="nav-item" role="presentation">
									<a 
										class="nav-link mb-sm-3 mb-md-0" 
										data-bs-toggle="tab" 
										href="#users"
										id="users-tab"
									>
										Users
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-12">
						<div class="tab-content mb-4" id="personal-content">
							<div id="personal-info" class="tab-pane fade show active" role="tabpanel" aria-labelledby="personal-info-tab">
								<OrganizationForm 
									:organization="organization"
									@submit="SaveOrganization"
								/>
							</div>
							<div id="users" class="tab-pane fade" role="tabpanel" aria-labelledby="users-tab">
								<Users 
									:links="organization.links" 
									:organization_id="organization_id"
									@refresh="GetOrganization"
								/>
							</div>
						</div>
					</div>				
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import Users from './components/Users'
	import OrganizationForm from './components/OrganizationForm'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Profile',
		props: {
			organization_id: {
				type: [String, Number],
				required: true
			}
		},
		components: {
			Users,
			OrganizationForm
		},
		data () {
			return {
				organization: 	null
			}
		},
		computed: {
			...mapGetters('auth', [
				'all_roles'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetOrganization()
			{
				this.StartLoading()

				window.api.call('post', '/get-organization', {
						organization_id: this.organization_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.organization 	= data.organization

						}else{
							
							Swal.fire({
								icon: 	'error',
								title: 	'Oopss...',
								text: 	'There was an error getting the data, this organization may be disabled or you dont have permission.'
							})
							.then(() => {
								this.$router.push({ name: 'Organizations' })
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			SaveOrganization(organization)
			{
				console.log(organization)
			}
		},
		beforeMount()
		{
			let allowed
			if(this.all_roles[this.organization_id])
			{
				allowed 		= this.all_roles[this.organization_id].indexOf('organization.area') > -1
			}

			if(allowed)
			{
				this.GetOrganization()
			
			}else{

				this.$router.push({ name: 'Home' })
			}
		}
	}
</script>

<style lang="css" scoped>
	.organization-profile .avatar
	{
		border: 1px solid #bbb;
	    width: 160px;
	    height: 160px;
	    border-radius: 1rem;
	    background-color: #ccc;
	    display: flex;
	    align-items: center;
	    justify-content: center;
        font-size: 3rem;
    	font-weight: 700;
	}
</style>