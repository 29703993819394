<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-block mx-auto mb-4 pb-md-2">
					<span>Upload</span>
					<h4 class="m-0 font-24 font-md-32">Upload queue</h4>
				</div>
			</div>
			
			<div class="col-12">
				<Uploader
					ref="uploader"
					@queue="QueueUpdate"
					@complete="QueueStatus"
					@uploading="ToggleUploadingStatus"
				/>
			</div>

			<div class="col-12 mt-3" v-show="existents_an.length > 0">
				<div class="alert alert-warning alert-dismissible fade show" role="alert">
					Studies with these accessions numbers already exist: {{ existents_an.join(', ') }}
					<button type="button" class="btn-close" aria-label="Close" @click="existents_an = []" ></button>
				</div>
			</div>

			<div class="col-12 mt-3">
				<UploadQueue
					ref="upload_queue"
					:queue="queue"
					:uploading="uploading"
					:complete="complete"
					@remove-file="RemoveFile"
					@clear-queue="ClearQueue"
					@start-upload="StartUpload"
				/>
			</div>
		</div>		
	</div>
</template>

<script>
	import Uploader from './components/Uploader'
	import UploadQueue from './components/UploadQueue'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',
		components: 	{
			Uploader,
			UploadQueue
		},
		data () {
			return {
				queue: 			[],
				uploading: 		false,
				complete: 		false,
				existents_an:	[]
			}
		},
		methods: {
			...mapActions('system', [
				'UpdatedFileUpload'
			]),
			QueueUpdate(arr)
			{
				this.queue 		= arr
			},
			RemoveFile(file_id)
			{
				this.$refs.uploader.RemoveFileFromQueue(file_id)
			},
			StartUpload()
			{
				this.$refs.uploader.StartUpload()
			},
			ClearQueue()
			{
				this.$refs.uploader.ClearQueue()
			},
			ToggleUploadingStatus(val)
			{
				this.uploading 	= val
			},
			QueueStatus(complete)
			{
				this.complete 	= complete
			}
		},
		watch: {
			updated_file_upload: {
				deep: true,
				immediate: true,
				handler: function (e, oldVal) {

					if ( !e ) return; 

					if ( e.type == 'already_exists' && !this.existents_an.includes(e.accession_number) ) {
						this.existents_an.push(e.accession_number)
					}

				}
			},
		},
		computed: {
			...mapGetters('system', [
				'updated_file_upload'
			])
		},
		beforeUnmount() {
			this.existents_an = []
			this.UpdatedFileUpload(null)
		},
	}
</script>

<style lang="css" scoped>
</style>