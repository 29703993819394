import Vuex from './vuex'
import _Echo from 'laravel-echo'
import Swal from 'sweetalert2'

class Echo
{
	constructor()
	{
		this.token 				= null
		this.user   			= null
		this._echo 				= null

		this.Toast 				= Swal.mixin({
				toast: 				true,
				position: 			'top-end',
				showConfirmButton: 	false,
				timer: 				3000,
				timerProgressBar: 	true,
				didOpen: (toast) => {
				    toast.addEventListener('mouseenter', Swal.stopTimer)
				    toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})
	}

    EchoInstance()
    {
		this.token 			= Vuex.getters['auth/token']
		this.user			= Vuex.getters['auth/user']

        if(this.token)
        {
            return new _Echo({
                broadcaster: 	'pusher',
                key:            process.env.VUE_APP_WEBSOCKET_APP_KEY,
                wsHost:         process.env.VUE_APP_WEBSOCKET_APP_HOST,
                wsPort:         process.env.VUE_APP_WEBSOCKET_APP_PORT,
                forceTLS:  		false,
                encrypted:      process.env.VUE_APP_WEBSOCKET_APP_ENCRYPTED == 'true',
                cluster:        process.env.VUE_APP_WEBSOCKET_APP_CLUSTER,
                    disableStats:          false,
                    authEndpoint: 		   `${process.env.VUE_APP_API_URL}/api/broadcasting/auth`, 
                    auth:                  {
                        headers:               {
                            Authorization:      `Bearer ${ this.token }`,
                            Accept:             'application/json',
                    }
                }
            })
        }

        console.log('an error occurred while starting the echo.')

        return null
    }

    LeaveOrganization()
    {
    	const organization_id 	= Vuex.getters['auth/organization_selected_id']

    	if(!this._echo)
    	{
	    	this._echo      		= this.EchoInstance()
    	}

		if(this._echo && organization_id)
		{
			this._echo.leave(`organization.${ organization_id }`)

		}else{

			console.log('an error occurred while starting the echo.')
		}
    }

    RunPrivate()
    {
    	if(!this._echo)
    	{
	    	this._echo      		= this.EchoInstance()
    	}

		if(this._echo)
		{
			
			this._echo.private(`user.${ this.user.id }`)
				.listen('NotificationEvent', (e) => {
					Vuex.dispatch('notification/BroadcastedNotification', e)
				})
				.listen('FileUploadFeedback', (e) => { 
					Vuex.dispatch('system/UpdatedFileUpload', e)
				})

		}else{

	    	console.log('an error occurred while starting the echo.')
	    }  
    }

    RunOrganization()
    {
    	const organization_id 	= Vuex.getters['auth/organization_selected_id']

		if(!this._echo)
    	{
	    	this._echo      		= this.EchoInstance()
    	}

		if(this._echo && organization_id)
		{ 
			this._echo.private(`organization.${ organization_id }`)
				.listen('NotificationEvent', (e) => {
					Vuex.dispatch('notification/BroadcastedNotification', e)
				})
				.listen('AuditStepWalk', (e) => {
					Vuex.dispatch('system/UpdatedAudit', e)
				})
				.listen('StudyUpdateEvent', (e) => {
					Vuex.dispatch('system/StudyUpdateEvent', e) 
				})

			this._echo.join(`organization.${ organization_id }`)
	            .here((accounts) => {
	            	//TRAZ TODO MUNDO QUE TÁ LOGADO LOGO QUANDO ENTRA NO CANAL
				    Vuex.dispatch('auth/ResetOnlineAccount', accounts)
			    })
			    .joining((account) => {
			    	//DISPARA SEMPRE QUE ENTRA ALGUEM NO CANAL
			        let name 		= account.name.split(' ')
			        let full_name	= name[0]
			        full_name 		+= ` ${name[name.length - 1].charAt(0).toUpperCase()}.`

			        /*this.Toast.fire({
			        	icon: 		'success',
			        	title: 		`${full_name} just entered.`
			        })*/

			        Vuex.dispatch('auth/AddOnlineAccount', account)

			    })
			    .leaving((account) => {
			    	//DISPARA SEMPRE QUE ALGUEM SAI DO CANAL
			        let name 		= account.name.split(' ')
			        let full_name	= name[0]
			        full_name 		+= ` ${name[name.length - 1].charAt(0).toUpperCase()}.`

			        /*this.Toast.fire({
			        	icon: 		'error',
			        	title: 		`${full_name} left the app.`
			        })*/

			        Vuex.dispatch('auth/RemOnlineAccount', account)
			    })
			    .error((error) => {
			    	//QUANDO DA ERRO, OBVIO
			        console.error(error);
			    });

	    }else{

	    	console.log('an error occurred while starting the echo.')
	    }        
    }
}

export default Echo