<template>
	<div>
		<router-view v-slot="{ Component }">
			<transition name="fade" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
	</div>
</template>

<script>
	export default {

		name: 'EmptyParent',
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>

</style>