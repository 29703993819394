<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-block mx-auto mb-4 pb-md-2">
					<span>Artificial Intelligence</span>
					<h4 class="m-0 font-24 font-md-32">AI Classifications</h4>
				</div>
			</div>
			<div class="col-12">
				<div class="card border-0">
					<div class="row align-items-center justify-content-end mb-1">
						<div class="col-12 col-md">
							<div class="d-flex justify-content-between justify-content-md-start flex-column flex-md-row">
								<button
									type="button"
									class="btn btn-danger px-4 me-md-3 mb-3 mb-md-0"
									:disabled="selected_studies.length < 1"
									@click="SelectOptionsToDeleteStudies"
								>
									Delete these selected ({{ selected_studies.length }})
								</button>
								<button
									type="button"
									class="btn btn-success px-5 text-white mb-3 mb-md-0"
									:disabled="selected_studies.length < 1"
									@click="ConfirmStudies"
								>
									Send these to AI ({{ selected_studies.length }})
									<i class="bi bi-box-arrow-in-up-right ms-2"></i>
								</button>
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-4">
							<div class="input-group">
								<span class="input-group-text" id="search-studies">
									<i class="bi bi-search"></i>
								</span> 
								<input 
									type="search" 
									class="form-control" 
									v-model="search" 
									placeholder="Search" 
									aria-label="Search" 
									aria-describedby="search-studies"
								>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="row align-items-center flex-nowrap">
							<div class="col-6">
								<div class="form-check">
								  	<input 
								  		class="form-check-input" 
								  		type="checkbox" 
								  		id="select-all"
								  		v-model="select_all"
										@change="SelectAll($event)"
								  	>
								  	<label class="form-check-label" for="select-all"></label>
								</div>
							</div>
							<div class="col-6">
								<div class="d-flex justify-content-end align-items-center">
									<div v-if="selected_studies.length > 0">
										<p class="text-right mb-0">
											<b>{{ (selected_studies.length).toString().padStart(2, '0') }}</b>
											selected file(s) of <b>{{ total_studies }}</b> studies.
										</p>
									</div>
									<div v-else>
										<p class="text-right mb-0">
											<b>{{ total_studies }}</b> studies.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<template v-if="filtered_studies.length > 0">	
					<transition-group tag="div" name="fade" mode="out-in">
						<div 
							v-for="study in filtered_studies" 
							:key="study.id"
							class="card bg-study mb-3 shadow-soft"
							:class="{ 'selected': CheckIfSelected(study.id) }"
							v-show="!study.hide"
						>
							<div class="card-body pb-0">
								<div class="row mb-3">
									<div class="col col-md-auto order-1">
										<div class="form-check">
										  	<input 
										  		class="form-check-input" 
										  		type="checkbox" 
										  		:id="`select_study_${study.id}`"
										  		:value="study.id"
										  		v-model="selected_studies"
										  	>
										  	<label 
										  		class="form-check-label" 
										  		:for="`select_study_${study.id}`"
										  	>
										    	
										  	</label>
										</div>
									</div>
									<div 
										class="col-12 col-md order-3 order-md-2 clickable"
										@click="study.collapse_all.toggle()"
									>
										<p class="m-0">
											Our token: 
											<b>{{ study.unique_token }}</b>
										</p>
										<p class="m-0">
											Accession number: 
											<b>{{ study.accession_number }}</b>
										</p>
										<p class="m-0">
											Patient: 
											<b>{{ study.patient.patient_number }} - {{study.patient.name }}
											</b>
										</p>
										<p class="m-0">
											Equipment: 
											<b>{{ study.equipment.station_name }}</b>
										</p>
										<div class="w-100">
											Images: 
											<b>{{ study.images_total }}</b>
										</div>
										<div class="w-100">
											Status: 
											<div :class="`badge bg-${ study.status_object.color } font-16 px-4`">
												{{ study.status_object.text }}
											</div>
										</div>
										<div class="w-100">
											Uploaded at: 
											<b>{{ study.created_at }}</b>
										</div>
										<div class="w-100">
											Study uploaded by: 
											<b>{{ study.requester_name }}</b>
										</div>
									</div>
									<div class="col-auto col-md-auto order-2 order-md-3">
										<a 
											href="javascript:;"
											class="text-danger" 
											@click="SelectOptionsToDeleteStudy(study)"
											v-if="study.status === 1"
										>
											<i class="bi bi-trash font-24"></i>
										</a> 
										<a 
											href="javascript:;"
											class="text-danger" 
											@click="GoToResultSearch(study)"
											v-if="study.status === 4"
										>
											<button type="button" class="btn btn-tertiary px-3">
												result
												<i class="bi bi-box-arrow-up-right ms-1"></i>
											</button>
										</a>
									</div>
								</div>
							</div>
							<div class="sides collapse" :id="`all_study_${study.id}`">
								<div 
									class="p-3 bg-side m-3 rounded"
									v-show="study.images.left.length"
								>
									<div class="row">
										<div class="col-12">
											<p 
												class="m-0 clickable" 
												@click="study.collapse_left.toggle()"
											>
												<b>LEFT</b>
											</p>
										</div>
									</div>
									<div class="row collapse" :id="`left_study_${study.id}`">
										<div 
											class="col-12 mt-3"
											v-for="image in study.images.left"
											:key="image.id"
										>
											<div class="card bg-image shadow-lg">
												<div class="card-body">
													<div class="row">
														<div class="col-auto">
															<div 
																class="image-dicom clickable"
																@click="ZoomImage(image)"
															>
																<img :src="GetImageLink(image, 'thumb')">
															</div>
														</div>
														<div class="col">
															<div class="row align-items-center">
																<div class="col-12">
																	<p class="m-0">
																		File: 
																		<b>{{ image.original_name }}</b>
																	</p>
																</div>
																<div class="col-12">
																	<p class="m-0">
																		Date: 
																		<b>{{ image.dicom_date }}</b>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div 
									class="p-3 bg-side m-3 mt-0 rounded"
									v-show="study.images.right.length"
								>
									<div class="row">
										<div class="col-12">
											<p 
												class="m-0 clickable"
												@click="study.collapse_right.toggle()"
											>
												<b>RIGHT</b>
											</p>
										</div>
									</div>
									<div class="row collapse" :id="`right_study_${study.id}`">
										<div 
											class="col-12 mt-3"
											v-for="image in study.images.right"
											:key="image.id"
										>
											<div class="card bg-image shadow-lg">
												<div class="card-body">
													<div class="row">
														<div class="col-auto">
															<div 
																class="image-dicom clickable"
																@click="ZoomImage(image)"
															>
																<img :src="GetImageLink(image, 'thumb')">
															</div>
														</div>
														<div class="col">
															<div class="row align-items-center">
																<div class="col-12">
																	<p>
																		File: 
																		<b>{{ image.original_name }}</b>
																	</p>
																</div>
																<div class="col-12">
																	<p class="m-0">
																		Date: 
																		<b>{{ image.dicom_date }}</b>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div 
									class="p-3 bg-side m-3 mt-0 rounded"
									v-show="study.images.unknown.length"
								>
									<div class="row">
										<div class="col-12">
											<p 
												class="m-0 clickable"
												@click="study.collapse_unknown.toggle()"
											>
												<b>UNKNOWN</b>
											</p>
										</div>
									</div>
									<div class="row collapse" :id="`unknown_study_${study.id}`">
										<div 
											class="col-12 mt-3"
											v-for="image in study.images.unknown"
											:key="image.id"
										>
											<div class="card bg-image shadow-lg">
												<div class="card-body">
													<div class="row">
														<div class="col-auto">
															<div 
																class="image-dicom clickable"
																@click="ZoomImage(image)"
															>
																<img :src="GetImageLink(image, 'thumb')">
															</div>
														</div>
														<div class="col">
															<div class="row align-items-center">
																<div class="col-12">
																	<p>
																		File: 
																		<b>{{ image.original_name }}</b>
																	</p>
																</div>
																<div class="col-12">
																	<p class="m-0">
																		Date: 
																		<b>{{ image.dicom_date }}</b>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</transition-group>
				</template>
				<template v-else>
					<template v-if="studies.length > 0">
						<div class="card bg-study">
							<div class="card-body">
								<div class="row">
									<div class="col-12">
										<p class="m-0 font-18 text-center">
											There are no studies with <b>"{{ search }}"</b> key to show.
										</p>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="card bg-study shadow-soft">
							<div class="card-body">
								<div class="row">
									<div class="col-12">
										<p class="m-0 font-18 text-center">
											<b>There are no studies to show</b>
										</p>
									</div>
								</div>
							</div>
						</div>
					</template>					
				</template>
			</div>
		</div>
		<div 
			class="modal fade" 
			id="options-for-delete" 
			tabindex="-1" 
			aria-labelledby="options-for-delete" 
			aria-hidden="true"
		>
			<div class="modal-dialog">
			    <div class="modal-content">
			    	<div class="modal-header">
			    		<h5 class="modal-title">Delete this</h5>
			    	</div>
			        <div class="modal-body">
						<form class="row justify-content-between">
							
							<template v-if="study_to_delete">
								<div class="col-12">
									<p class="text-center">
										<b>Choose the options to delete this study</b>
									</p>
								</div>
								<div class="col-12">
									<p class="text-center font-18 mb-4">
										Accession number: <b>{{ study_to_delete.accession_number }}</b>
									</p>
								</div>
							</template>
							<template v-if="studies_to_delete.length > 0">
								<div class="col-12">
									<p class="text-center">
										<b>Choose the options to delete these studies</b>
									</p>
								</div>
								<div class="col-12">
									<p class="text-center font-18">
										Selected studies: <b>{{ studies_to_delete.length }}</b>
									</p>
								</div>
								<div class="col-12">
									<p class="text-center font-18 mb-4">
										Accession numbers: 
											<span 
												v-for="(study, index) in studies_to_delete" 
												:key="study.accession_number"
											>
												<b>{{ study.accession_number }}</b>
												<template v-if="(index + 1) < studies_to_delete.length">,
												</template>
											</span>
									</p>
								</div>
							</template>
							<div class="col-12">
								<div class="form-check">
								  	<input 
								  		class="form-check-input" 
								  		type="checkbox" 
								  		value="dicom" 
								  		v-model='delete_options'
								  		id="only_dicom"
								  		:disabled="delete_this_study"
								  	>
								  	<label class="form-check-label" for="only_dicom">
								    	Delete only DICOM's
								  	</label>
								</div>
							</div>
							<div class="col-12">
								<div class="form-check">
								  	<input 
								  		class="form-check-input" 
								  		type="checkbox" 
								  		value="study" 
								  		v-model='delete_options'
								  		id="all_study"
								  	>
								  	<label class="form-check-label" for="all_study">
								    	Delete all this ( entire study )
								  	</label>
								</div>
							</div>
							<div class="col-12 text-center mt-3">
								<button 
									type="button" 
									class="btn btn-danger px-5" 
									@click="DeleteStudy"
									:disabled="delete_options.length === 0"
								>
									Delete
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div 
			class="modal fade" 
			id="confirm-studies" 
			tabindex="-1" 
			aria-labelledby="confirm-studies" 
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
			    <div class="modal-content">
			    	<div class="modal-header">
			    		<h5 class="modal-title">Confirm these studies</h5>
			    	</div>
			        <div class="modal-body">
			        	<div class="row">
			        		<div class="col-12">
			        			<p class="text-center font-18">
			        				Verify below for studies that will be placed in the classification queue for AI:
			        			</p>
			        		</div>
			        		<div class="col-12">
			        			<p class="text-center font-18">
			        				Selected studies: <b>{{ studies_to_confirm.length }}</b>
			        			</p>
			        		</div>
			        		<div class="col-12">
			        			<p class="text-center font-18 mb-4">
									Accession numbers: 
										<span 
											v-for="(study, index) in studies_to_confirm" 
											:key="study.accession_number"
										>
											<b>{{ study.accession_number }}</b>
											<template v-if="(index + 1) < studies_to_confirm.length">
												,
											</template>
										</span>
								</p>
			        		</div>
			        		<div class="col-12 mt-4 mb-3">
			        			<div class="form-check">
								  	<input 
								  		class="form-check-input" 
								  		type="checkbox"
								  		v-model='agreement_accepted'
								  		id="agreement_accepted"
								  	>
								  	<label class="form-check-label" for="agreement_accepted">
								    	I agree to the <a href="javascript:;" class="text-tertiary"><b>terms and conditions</b></a> and the <a href="javascript:;" class="text-tertiary"><b>privacy policy</b></a>.
								  	</label>
								</div>
			        		</div>
			        		<div class="col-12 text-center">
			        			<button
			        				class="px-5 btn btn-success text-white"
			        				type="button"
			        				:disabled="!agreement_accepted"
			        				@click="SendStudiesToAIQueue"
			        			>
			        				Confirm and Start !
			        			</button>
			        		</div>
			        	</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Index',

		data () {
			return {
				studies: 					[],
				selected_studies: 			[],
				select_all: 				false,
				search: 					'',
				options_for_delete_modal: 	null,
				delete_options: 			[],
				study_to_delete: 			null,
				studies_to_delete: 			[],
				confirm_studies_modal: 		null,
				studies_to_confirm: 		[],
				agreement_accepted: 		false
			}
		},
		watch: {
			// select_all(val)
			// {
			// 	if(!val)
			// 	{
			// 		this.selected_studies 	= []

			// 	}else{

			// 		this.selected_studies 	= this.filtered_studies.filter(s => s.status === 1)
			// 			.map( s => s.id )
			// 	}
			// },
			delete_this_study(val)
			{
				if(val)
				{
					this.delete_options.push('dicom')

				}else{

					this.delete_options 	= []
				}
			},
			updated_study(val) {

				if ( ! val ) return

				this.studies.forEach((study, i) => { 

					if ( study.id == val.id ) {
 
						this.studies[i].status = val.status

					}
				});
				
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id', 'token'
			]),
			...mapGetters('system', [
				'updated_study'
			]),
			delete_this_study()
			{
				return typeof this.delete_options.find(d => d === 'study') !== 'undefined'
			},
			filtered_studies()
			{
				const search = window.helpers.RemoveAccent(this.search.toString().toLowerCase().trim())

				return this.studies.map( study => {

					let allowed 	= true

					if(search)
					{
						allowed 		= false

						const items 	= [
							'accession_number', 
							'created_at', 
							'id', 
							'patient.name', 
							'patient.patient_number', 
							'equipment.sn', 
							'equipment.station_name',
							'status'
						]

						items.forEach( p => {

							let item 		= window._.get(study, p)
							item 			= item.toString().toLowerCase()
							item 			= window.helpers.RemoveAccent(item)

							if(item)
							{
								if(!allowed)
								{
									allowed 	= item.indexOf(search) > -1
									return
								}							
							}
						})
					}

					study.status_object 	= window.helpers.GetStudyStatusObject(study.status)

					study.hide 				= !allowed

					study.images_total 		= study.images.left.length + study.images.right.length + study.images.unknown.length

					return study
				})
			},
			total_studies()
			{
				return this.filtered_studies.length.toString().padStart(2, '0')
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetStudies()
			{
				this.StartLoading()

				return window.api.call('post', '/get-studies', {
						organization_id: 	this.organization_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.studies 		= data.studies

						}else{

							Swal.fire({
								title: 	'Opss...',
								text: 	'An error has occurred, contact support.',
								icon: 	'error'
							})
						}
					})
					.finally(async () => {
						this.studies 	= await this.ParseCollapse(this.studies)

						this.FinishLoading()
					})
			},
			SelectOptionsToDeleteStudy(study)
			{
				this.delete_options  	= []
				this.study_to_delete 	= study
				this.studies_to_delete  = []

				this.options_for_delete_modal.show()
			},
			SelectOptionsToDeleteStudies()
			{
				this.delete_options  	= []
				this.study_to_delete 	= null
				this.studies_to_delete  = this.studies.filter( s => this.selected_studies.indexOf(s.id) > -1)

				this.options_for_delete_modal.show()
			},
			ConfirmStudies()
			{
				this.studies_to_confirm = this.studies.filter( s => this.selected_studies.indexOf(s.id) > -1)
				this.agreement_accepted = false

				this.confirm_studies_modal.show()
			},
			CheckIfSelected(study_id)
			{
				return this.selected_studies.indexOf(study_id) > -1
			},
			SendStudiesToAIQueue()
			{
				this.StartLoading()

				window.api.call('post', '/send-to-ai-classification', {
						study_ids: this.selected_studies	
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								title: 		'Successfully sent !',
								text: 		'Now, just wait for the AI classification, we will send you a notification when the process is gone.',
								icon: 		'success',
							})

						}else{

							Swal.fire({
								title: 		'Opss...',
								text: 		'An error has occurred while trying to put studies in the upload queue, contact support',
								icon: 		'error',
							})
						}
					})
					.finally(() => {

						this.confirm_studies_modal.hide()
						this.selected_studies 	= []

						this.GetStudies()
						this.FinishLoading()
					})
			},
			DeleteStudy()
			{
				let obj 	= {
					mode: 		this.delete_options
				}

				let url 	= ''

				if(!this.study_to_delete && this.studies_to_delete.length < 1)
				{
					return

				}else if(!this.study_to_delete && this.studies_to_delete.length > 0)
				{
					obj.study_ids 	= this.studies_to_delete.map( s => s.id )

					url 			= '/delete-studies'

				}else if(this.study_to_delete && this.studies_to_delete.length < 1)
				{
					obj.study_id 	= this.study_to_delete.id

					url 			= '/delete-study'

				}else{

					return 
				}

				this.StartLoading()

				window.api.call('post', url, obj)
					.then(async ({data}) => {
						if(data.response)
						{
							await this.GetStudies()

							Swal.fire({
								toast:  	true,
								showConfirmButton: false,
								timerProgressBar: true,
								position:   'top',
								title: 		'Items deleted successfully',
								icon: 		'success',
								timer: 		3000
							})

						}else{

							Swal.fire({
								title: 		'Opss...',
								text: 		'An error has occurred on delete this items, contact support',
								icon: 		'error',
							})
						}
					})
					.finally(() => {

						this.delete_options 	= []
						this.study_to_delete 	= null
						this.select_all 		= false

						this.options_for_delete_modal.hide()

						this.FinishLoading()
					})
			},
			ParseCollapse(studies)
			{
				return studies.map( study => {

					if(study.id)
					{
						let collapse_all 	 = document.getElementById(`all_study_${study.id}`)
						study.collapse_all  = new window.bootstrap.Collapse(collapse_all, {
							toggle: false
						})

						let collapse_left 	 = document.getElementById(`left_study_${study.id}`)
						study.collapse_left  = new window.bootstrap.Collapse(collapse_left, {
							toggle: false
						})

						let collapse_right 	 = document.getElementById(`right_study_${study.id}`)
						study.collapse_right = new window.bootstrap.Collapse(collapse_right, {
							toggle: false
						})	

						let collapse_unknown = document.getElementById(`unknown_study_${study.id}`)
						study.collapse_unknown = new window.bootstrap.Collapse(collapse_unknown, {
							toggle: false
						})
					}

					return study
				})
			},
			ZoomImage(image)
			{
				Swal.fire({
					imageUrl: this.GetImageLink(image),
					showConfirmButton: false,
					imageWidth: 720,
					imageHeight: 720,
					imageAlt: image.original_name,
				})
			},
			GetImageLink(image, mode = null)
			{
				let size 	= ''

				switch(mode)
				{
					case 'thumb':
						size 		= '&width=100'
					break
					default: 

					break
				}

				let url  = process.env.VUE_APP_API_URL
				url 	+= `/api/app/get-image/${this.organization_selected_id}/${image.jpg}`
				url 	+= `?token=${this.token}${size}`

				return url
			},
			GoToResultSearch(study) {
 
				this.$router.push({ name: 'Results', params: { 
						search: study.accession_number 
					}
				})
			},
			SelectAll(event)
			{
				const select_all = document.querySelector("#select-all.form-check-input")

				const inputs = document.querySelectorAll(".form-check-input")

				for (const input of inputs) {
					const id = input.getAttribute("id")

					if(id.indexOf("select_study") > -1) {
						const id_int = parseInt(id.substring(13))

						if(select_all.checked) {
							this.selected_studies.push(id_int)
						} else {
							const index = this.selected_studies.indexOf(id_int)

							this.selected_studies.splice(index, 1)
						}
						
						input.checked = select_all.checked
					}
				}
			}
		},
		mounted()
		{
			this.options_for_delete_modal = new window.bootstrap.Modal(document.getElementById('options-for-delete'))
			this.confirm_studies_modal    = new window.bootstrap.Modal(document.getElementById('confirm-studies'))
		},
		async beforeMount()
		{
			
			await this.GetStudies()
		}
	}
</script>

<style lang="css" scoped>
	
	.image-dicom
	{
		width: 50px;
		height: 50px;
	}	

	.image-dicom img
	{
		border-radius: 8px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.bg-study
	{
		background-color: #d6d6d6 !important;
		border: 1px solid #ccc;
	}

	.bg-study.selected
	{
		background-color: #b0ccf1 !important;
	}

	.bg-side
	{
		background-color: #a7a7a7 !important;
		color: #fff;
	}

	.selected .bg-side
	{
		background-color: #6693ce !important;
	}

	.bg-image
	{
	    background-color: #7b7b7b !important;
    	border-color: #888888 !important;
    	color: #fff;
	}

	.selected .bg-image
	{
		background-color: #1d58a5 !important;
		border-color: #5d84b7 !important;
	}
</style>