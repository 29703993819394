<template>
	<div class="data-tables">
		<div class="row align-items-center pb-2">
			<div class="col-12">
				<div class="table-responsive" v-if="headers.length > 0">
					<table class="table-custom">
						<thead>
							<tr>
								<th v-if="checkbox">
									<div class="form-check">
										<input 
											type="checkbox" 
											id="checkbox-all"
											v-model="check_all"
										>
										<label for="checkbox-all"></label>
									</div>
								</th>
								<th 
									v-for="(header,index) in headers" 
									:key="index"
									class="text-muted"
									:class="{
										'text-center': 	header.align == 'center',
										'text-left': 	header.align == 'left',
										'text-right': 	header.align == 'right',
										'sortable': 	header.value && header.sortable,
										'active': 		sorted_by.column == header.value
									}"
								>
									<div class="th-content" @click="SortData(header)">
										<template v-if="excel_filter && header.sortable">
											<div class="filter" @click.stop>
												<a 
													href="javascript:;" class="me-2 btn-tertiary"
													:ref="`filter-${index}`"
													@click="OpenExcelFilter(index)"
												>
													<i class="bi bi-filter font-14"></i>
													<template v-if="excel_filtered_items[`filter-${index}`] && excel_filtered_items[`filter-${index}`].length > 0">
														<div class="indicator">
															{{ excel_filtered_items[`filter-${index}`].length }}
														</div>
													</template>
												</a>									
											</div>
										</template>
										{{header.text}} 
										<div class="sort-icon">
											<i 
												:class="`bi bi-${sorted_by.icon}`" 
												v-if="sorted_by.column == header.value"
											></i>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<transition name="fast-fade" mode="out-in">
							<tbody v-if="filtered_items.length > 0">
								<tr 
									v-for="(item,index) in filtered_items"
									:class="`${ tr_button ? 'tr-btn' : '' } ${row_color ? item.row_status_color ? item.row_status_color : item.row_color : ''}`"	
									:key="index * Math.random()"
									@click="ByClickOnTR(item)"
								>
									<td v-if="checkbox" scope="col">
										<div class="td-content">
											<div class="form-check">
												<input 
													type="checkbox" 
													:id="`checkbox-${index}`"
													:value="item[checkbox_value]"
													v-model="selected_checkboxes"
													@change="EmitCheckboxes"
												>
												<label :for="`checkbox-${index}`"></label>
											</div>
										</div>											
									</td>
									<slot name="body" :item="item" :index="index"></slot>
								</tr>
							</tbody>
							<tbody 
								v-else-if="filtered_items.length < 1 && items.length > 0"
								key="empty"
							>
								<tr>
									<td :colspan="headers.length">
										<slot name="no-match">
											<div class="alert alert-warning m-0 font-18">	
												Your search for "<b>{{ search }}</b>" returned no result.
											</div>
										</slot>
									</td>
								</tr>
							</tbody>
							<tbody 
								v-else
								key="no-data"
							>
								<tr>
									<td 
										:colspan="checkbox ? headers.length + 1 : headers.length"
										class="td-alert"
									>
										<slot name="no-data">
											<div class="alert alert-info m-0 font-18">						
												No items to show.
											</div>
										</slot>
									</td>
								</tr>
							</tbody>
						</transition>
						<tfoot v-if="items.length > 0">
							<tr>
								<slot name="footer" :items="items"></slot>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div 
				class="col-12 col-md text-center text-md-left align-self-center counter text-muted mt-2" 
				v-if="items.length > 0"
			>
				<b>
					{{paginate ? 
						`Showing ${paginate_setup.init_item} to ${paginate_setup.end_item} of ${items.length} items` : 
						`Total: ${items.length} items`}}
				</b>
			</div>
			<div class="col-12 col-md-auto mt-2" v-if="paginate">
				<ul class="pagination m-0">
					<li class="page-item">
						<a 
							href="javascript:;"
							class="page-link text-tertiary" 
							:disabled="paginate_setup.page === 1"
							@click="WalkPaginate('prev')"
						>
							Previous
						</a>
					</li>
					<li class="page-item active">
						<a 
							href="javascript:;"
							class="page-link bg-tertiary" 
							disabled
						>
							{{ paginate_setup.page }}
						</a>
					</li>
					<li class="page-item">
						<a 
							href="javascript:;"
							class="page-link text-tertiary" 
							:disabled="paginate_setup.page === paginate_setup.total_pages || filtered_items.length < 1"
							@click="WalkPaginate('next')"
						>
							Next
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div 
			class="modal fade" 
			id="excel-filter" 
			tabindex="-1" 
			aria-labelledby="excel-filter" 
			aria-hidden="true"
			aria-modal="true"
		>
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<div class="row flex-nowrap align-items-center w-100">
							<div class="col">
								<h5 class="modal-title">
									Filter by: {{ excel_filter_column_object.text ?? '' }}
								</h5>
							</div>
							<div class="col-auto pe-0">
								<a 
									href="javascript:;"
									class="text-dark" 
									aria-label="Fechar"
									@click="excel_filter_column = null" 
								>
									<i class="bi bi-x-lg"></i>
								</a>
							</div>
						</div>	
					</div>
					<div class="modal-body">
						<div class="row align-items-center">
							<div class="col-12 mb-3">
								<div class="form-group">
									<input 
										type="search" 
										v-model="excel_filter_search" 
										class="form-control"
										placeholder="Search"
									>
								</div>
							</div>
							<template v-if="excel_filter_items.length > 0">
								<div 
									class="col-12"
									v-for="(item, i) in excel_filter_items"
									:key="i"
								>
									<div class="form-check">
										<input 
											type="checkbox" 
											:id="`filter-checkbox-${i}`"
											v-model="excel_filtered_items[excel_filter_column]"
											:value="item"
											class="form-check-input"
										>
										<label class="form-check-label" :for="`filter-checkbox-${i}`">
											{{ item ?? '( empty )' }}
										</label>
									</div>
								</div>
							</template>
							<template v-else>
								<div class="col-12">
									<div class="alert alert-warning m-0 text-uppercase">
										<b>No data at this column</b>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="modal-footer"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

		name: 'DataTables',
		emits: {
			'update:modelValue': {
				type:  		Array
			},
			'by-click-on-tr': {
				type:  		Object
			},
			selecteds: 		{
				type:  		Array
			}
		},
		props: {
			modelValue: 			{
				type: 			Array,
				required: 		false,
				default: 		() => []
			},
			type:			{
				type:			String,
				default:		''
			},
			headers: 		{
				type: 			Array,
				required: 		true,
				default: 		() => []
			},
			search: 		{
				type: 			String,
				required: 		false,
				default: 		''
			},
			paginate: 		{
				type: 			Boolean,
				required: 		false,
				default: 		false
			},
			excel_filter: 		{
				type: 			Boolean,
				default: 		false
			},
			items_per_page: {
				type: 			Number,
				required: 		false,
				default: 		10
			},
			tr_button: 		{
				type: 			Boolean,
				required: 		false,
				default: 		false
			},
			checkbox: 		{
				type: 			Boolean,
				default: 		false
			},
			row_color: 		{
				type: 			Boolean,
				default: 		false
			},
			checkbox_value: {
				type: 			String,
				default: 		'id'
			},
			scroll_update:	{
				type:			Boolean,
				required:		false,
				default:		false
			}
		},
		data () {
			return {
				current_page: 			1,
				items: 					[],
				sorted_by: 				{
					column: 				'',
					direction: 				'',
					icon: 					''
				},
				paginate_setup: 			{
				  	page: 					null,
				  	per_page: 				null,
				  	pre_page: 				null,
				  	next_page: 				null,
				  	total: 					null,
				  	total_pages: 			null,
				  	data: 				[]
				},
				selected_checkboxes: 	[],
				check_all: 				false,
				excel_filter_column: 	null,
				excel_filter_search: 	'',
				excel_filtered_items: 	{},
				excel_filter_modal: 	null
			}
		},
		computed: {
			excel_filter_items()
			{
				const search 	= window.helpers.RemoveAccent(this.excel_filter_search.toString().toLowerCase())

				if(this.excel_filter_column)
				{
					let column  = this.excel_filter_column.split('-')

					column 		= this.headers[column[1]]

					let columns = this.items.map( i => window._.get(i, column.value))

					//this.excel_filtered_items[this.excel_filter_column] 	= []

					return window._.uniq(columns).filter( c => {
						if(search && typeof c !== 'undefined')
						{
							let item = window.helpers.RemoveAccent(c.toString().toLowerCase())

							if(item.indexOf(search) > -1)
							{
								//this.excel_filtered_items[this.excel_filter_column].push(c)

								return true
							}

							return false
						}

						return true
					})
				}

				return []
			},
			excel_filter_column_object()
			{
				if(this.excel_filter_column)
				{
					let column  = this.excel_filter_column.split('-')

					return this.headers[column[1]]
				}

				return {}
			},
			filtered_items() 
			{
				const search 	= window.helpers.RemoveAccent(this.search.toString().toLowerCase())

				//BUSCA
				let items 		= this.items.filter( item => {

					let match 		= false

					let pos 		= 0

					//PERCORRE OS HEADERS					
					while(this.headers.length > pos)
					{
						const value 	= window._.get(item, this.headers[pos].value)

						match 			= true

						if(this.excel_filter)
						{	
							//SE HÁ ALGUM DADO SELECIONADO NESTA COLUNA
							if(this.excel_filtered_items[`filter-${pos}`] && this.excel_filtered_items[`filter-${pos}`].length > 0)
							{
								const entries 		= this.excel_filtered_items[`filter-${pos}`]

								if(entries.indexOf(value) === -1)
								{
									match 		= false

									break
								}
							}
						}
						
						if(search && match)
						{			
							//UPPERCASE E REMOVE ACENTOS
							let i 			= window.helpers.RemoveAccent((value ?? '').toString().toLowerCase())

							if(i.includes(search))
							{
								match 		= true

								break
							}

							match 	= false
						}

						pos++
					}

					return match
				})

				//PAGINATION
				if(this.paginate)
				{
					let items_per_page 		= this.items_per_page ? this.items_per_page : 5

					if ( items_per_page == 'all') items_per_page = Number.MAX_SAFE_INTEGER

					let numberOfPages 		= Math.ceil(items.length / items_per_page)

					let paginate 			= this.Paginate(items, this.current_page, items_per_page)

					items 					= paginate.data
					this.paginate_setup 	= paginate
				}

				return items
			}
		},
		watch: {
			excel_filter_column(val)
			{
				if(val)
				{
					this.excel_filter_modal.show()

				}else{

					this.excel_filter_modal.hide()
				}
			},
			modelValue: {
				handler(val)
				{
					this.items 					= val
					this.selected_checkboxes 	= []
					this.check_all 				= false
				},
				immediate: 	true,
				deep: 		true
			},
			headers: {
				handler(val)
				{
					if(val.length > 0)
					{
						val.forEach((v, index) => {
							this.excel_filtered_items[`filter-${index}`] 	= []
						})
					}
				},
				deep: true,
				immediate: true
			},
			check_all: {
				handler(val)
				{
					if(val)
					{
						this.selected_checkboxes = this.items.map( item => {
							return item[this.checkbox_value]
						})

					}else{

						this.selected_checkboxes 		= []
					}

					this.EmitCheckboxes()
				}
			}
		},
		methods: {
			OpenExcelFilter(index)
			{	
				this.excel_filter_search 	= ''

				if(this.excel_filter_column == `filter-${index}`)
				{
					this.excel_filter_column 	= null

				}else{

					this.excel_filter_column 	= `filter-${index}`
				}				
			},
			EmitCheckboxes()
			{
				this.$emit('selecteds', this.selected_checkboxes)
			},
			ByClickOnTR(item)
			{
				if(this.tr_button)
				{
					this.$emit('by-click-on-tr', item)
				}
			},
			OrderItems(key, order = 'ASC') 
			{
				const type 		= key.type
				const value 	= key.value

				return (a, b) => {

					let varA 	= null
					let varB 	= null

					switch(type)
					{
						case String:
							if(!window._.get(a, value))
							{
								return (order === 'DESC') ? 1 : -1
							}

							if(!window._.get(b, value))
							{
								return (order === 'DESC') ? -1 : 1
							}

							varA 		= window._.get(a, value).toString().toUpperCase()
							varB 		= window._.get(b, value).toString().toUpperCase()

							varA 		= window.helpers.RemoveAccent(varA)
							varB 		= window.helpers.RemoveAccent(varB)
							
							if(varA > varB) 
							{
								return (order === 'DESC') ? -1 : 1

							}else if(varA < varB) 
							{
								return (order === 'DESC') ? 1 : -1
							}

							return 0

						case Boolean:

							varA 		= window._.get(a, value)
							varB 		= window._.get(b, value)

							if(varA && !varB)
							{
								return (order === 'DESC') ? 1 : -1

							}else if(!varA && varB)
							{
								return (order === 'DESC') ? -1 : 1
							}

							return 0

						case Number:

							varA 		= parseFloat(window._.get(a, value))
							varB 		= parseFloat(window._.get(b, value))
							
							if (varA > varB) 
							{
								return (order === 'DESC') ? -1 : 1

							} else if (varA < varB) 
							{
								return (order === 'DESC') ? 1 : -1
							}

							return 0
						
						case Date:

							if(!window._.get(a, value))
							{
								return (order === 'DESC') ? 1 : -1
							}

							if(!window._.get(b, value))
							{
								return (order === 'DESC') ? -1 : 1
							}

							varA 		= new Date(window._.get(a, value))
							varB 		= new Date(window._.get(b, value))
							
							if (varA > varB) 
							{
								return (order === 'DESC') ? -1 : 1

							} else if (varA < varB) 
							{
								return (order === 'DESC') ? 1 : -1
							}

							return 0
						default:
							return 0
					}
				}
			},
			Paginate(items, page = 1, per_page = 5) 
			{
			    page 				= page || 1
				per_page 			= per_page || 5
			  	let offset 			= (page - 1) * per_page
				let paginatedItems 	= items.slice(offset).slice(0, per_page)
				let total_pages 	= Math.ceil(items.length / per_page)

				return {
				  	page: 			page,
				  	per_page: 		per_page,
				  	init_item: 		(offset + 1),
				  	end_item: 		(offset + paginatedItems.length),
				  	pre_page: 		page - 1 ? page - 1 : null,
				  	next_page: 		(total_pages > page) ? page + 1 : null,
				  	total: 			items.length,
				  	total_pages: 	total_pages,
				  	data: 			paginatedItems
			  	}
			},
			WalkPaginate(mode)
			{
				switch(mode)
				{
					case 'prev':
						this.current_page 	= this.current_page <= 1 ? 1 : this.current_page - 1
					break;
					case 'next':
						this.current_page 	= this.current_page < this.paginate_setup.total_pages ? this.current_page + 1 : this.current_page - this.paginate_setup.total_pages
					break;
				}
			},
			SortData(header)
			{
				console.log(header)
				if(header.value && header.sortable && header.type)
				{
					if(this.sorted_by.column != header.value)
					{
						this.sorted_by.column 		= header.value
						this.sorted_by.direction 	= ''
						this.sorted_by.icon 		= ''
					}

					let items 		= [...this.items]

					switch(this.sorted_by.direction)
					{
						case 'ASC':
							this.sorted_by.direction 	= 'DESC'
							this.sorted_by.icon 		= 'chevron-down'

							items.sort(this.OrderItems(header, 'DESC'))

							this.items 		= [...items]

						break;
						case 'DESC':

							this.sorted_by.column 		= ''
							this.sorted_by.direction 	= ''
							this.sorted_by.icon 		= ''

							items.sort(this.OrderItems(this.headers[0], 'ASC'))

							this.items 		= [...items]

						break;
						default: 
							this.sorted_by.direction 	= 'ASC'
							this.sorted_by.icon 		= 'chevron-up'

							items.sort(this.OrderItems(header, 'ASC'))

							this.items 		= [...items]

						break;
					}
				}
			}
		},
		mounted()
		{
			this.excel_filter_modal = new window.bootstrap.Modal(document.getElementById('excel-filter'))
		}
	}
</script>

<style lang="css" scoped>


</style>