<template>
    <div class="row">
        <div class="col-12">
            <form @submit.prevent="Save">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3">
                            <label>Name</label>
                            <input 
                                type="text"
                                class="form-control"
                                :class="{ 'is-invalid': v$.user.name.$error }"
                                v-model="v$.user.name.$model"
                            >
                            <div class="invalid-feedback">
                                Required
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label>Email</label>
                            <input 
                                type="email" 
                                class="form-control"
                                :class="{ 'is-invalid': v$.user.email.$error }"
                                v-model="v$.user.email.$model"
                            >
                            <div class="invalid-feedback" v-if="v$.user.email.required.$invalid">
                                Required
                            </div>
                            <div class="invalid-feedback" v-if="v$.user.email.email.$invalid">
                                Must be an valid email
                            </div>
                        </div>                        
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label>Phone</label>
                            <input 
                                type="tel"
                                v-mask="['+## (##) ####-####', '+## (##) #####-####']" 
                                class="form-control"
                                v-model="user.phone"
                            >
                        </div>                        
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label>Password</label>
                            <input 
                                type="password" 
                                class="form-control"
                                :class="{ 'is-invalid': v$.user.password.$error }"
                                v-model="v$.user.password.$model"
                            >
                            <div class="invalid-feedback" v-if="v$.user.password.required.$invalid">
                                Required
                            </div>
                            <div class="invalid-feedback" v-if="v$.user.password.minLength.$invalid">
                                Minimum of 06 characters
                            </div>
                        </div>                        
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label>Confirm the password</label>
                            <input 
                                type="password" 
                                class="form-control"
                                :class="{ 'is-invalid': v$.user.password_confirmation.$error }"
                                v-model="v$.user.password_confirmation.$model"
                            >
                            <div class="invalid-feedback" v-if="v$.user.password_confirmation.required.$invalid">
                                Required
                            </div>
                            <div class="invalid-feedback" v-if="v$.user.password_confirmation.SameAsPassword.$invalid">
                                Must be same as password
                            </div>
                        </div>                        
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-success text-white px-5">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
	import useVuelidate from '@vuelidate/core'
	import { required, sameAs, email, minLength } from '@vuelidate/validators'
    export default {
        name: 'UserForm',
        emits: {
			submit: {
				type: Object
			}
		},
        props: {
            email:  {
                type:       String,
                default:     null
            }
        },
		setup () 
		{
		    return { v$: useVuelidate() }
		},
        data() {
            return {
                user:   {
                    name:                   '',
                    email:                  '',
                    password:               '',
                    password_confirmation:  '',
                    phone:                  ''
                }
            }
        },
        watch:
        {
            email: {
                handler(email)
                {
                    if(email)
                    {
                        this.user.email         = email
                    }
                },
                immediate: true
            }
        },
        validations()
        {
            return {
                user: {
                    name:       {
                        required
                    },
                    email:      {
                        required,
                        email
                    },
                    password:   {
                        required,
                        minLength: minLength(6)
                    },
                    password_confirmation: {
                        required,
                        SameAsPassword: sameAs(this.user.password)
                    }
                }
            }
        },
        methods: {
            Save()
            {
                this.v$.user.$touch()

                if(!this.v$.user.$invalid)
                {
                    this.$emit('submit', this.user)
                }
            }
        }
    }
</script>

<style>

</style>