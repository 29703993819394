export default {
	running_requests: 		0,
	loading_message: 		null,
	updated_audit:			null,
	updated_study: 			null,
	updated_file_upload: 	null,
	menu_icons:				[
		{ role_area: 'upload.area', name: 'Upload', icon: 'file-earmark-arrow-up', route: { name: 'Upload' }},
		{ role_area: 'ai.classifications.area', name: 'AI Classific.', icon: 'cpu', route: { name: 'AIClassifications' }},
		{ role_area: 'results.area', name: 'Results', icon: 'card-checklist', route: { name: 'Results' }},
		{ role_area: 'patients.area', name: 'Patients', icon: 'people', route: { name: 'Patients'}},
		{ role_area: 'equipments.area', name: 'Equipments', icon: 'hdd-network', route: { name: 'Equipments'}},
		{ role_area: 'audit.area', name: 'Audit', icon: 'exclude', route: { name: 'Audit'}},
		{ role_area: null, name: 'Organizations', icon: 'building', route: { name: 'Organizations' }}
	]
}