<template>
	<div class="container patient-profile">
		<div class="row justify-content-center">
			<div class="col-12 mb-3">
				<router-link :to="{ name: 'Patients' }" class="btn btn-tertiary px-4">
					<i class="bi bi-arrow-left me-2"></i> Back
				</router-link>
			</div>
			<div class="col-12">
				<div class="row mt-4 mb-2">
					<template v-if="patient">
						<div class="col-12 col-md-auto text-center">
							<div class="avatar">
								<i class="bi bi-person font-32 text-muted"></i>
							</div>
						</div>
						<div class="col">
							<span>Patient</span>
							<h4 class="font-24 font-md-32 mb-4">
								{{ patient.name }}
							</h4>
							<div class="row">
								<div class="col-12 max-150">
									HIS ID
								</div>
								<div class="col text-dark">
									<b>{{ patient.link.extra.PatientID }}</b>
								</div>
							</div>
							<div class="row" v-if="patient.link.extra.PatientBirthDate">
								<div class="col-12 max-150">
									AGE
								</div>
								<div class="col text-dark">
									<b>{{ GetAge(patient.link.extra.PatientBirthDate) }}</b>
								</div>
							</div>
							<div class="row" v-if="patient.link.extra.PatientSex">
								<div class="col-12 max-150">
									SEX
								</div>
								<div class="col text-dark">
									<b>{{ patient.link.extra.PatientSex }}</b>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-150">
									CREATED AT
								</div>
								<div class="col text-dark">
									<b>{{ patient.created_at }}</b>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="col-12 my-4">
				<div class="nav-wrapper position-relative mb-2">
					<ul 
						class="nav nav-pills nav-fill flex-column flex-md-row" 
						role="tablist" 
						id="patient-tab"
					>
						<li class="nav-item" role="presentation">
							<a 
								class="nav-link mb-sm-3 mb-md-0 active" 
								data-bs-toggle="tab" 
								href="#personal-info"
								id="personal-info-tab"
							>
								Personal Info
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a 
								class="nav-link mb-sm-3 mb-md-0" 
								data-bs-toggle="tab" 
								href="#studies"
								id="studies-tab"
							>
								Studies
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-12">
				<div class="tab-content mb-4" id="patient-content">
					<div 
						id="personal-info" 
						class="tab-pane fade show active" 
						role="tabpanel" 
						aria-labelledby="personal-info-tab"
					>
						<PersonalInfo :patient="patient" @submit="SavePatient"/>
					</div>
					<div 
						id="studies" 
						class="tab-pane fade" 
						role="tabpanel" 
						aria-labelledby="studies-tab"
					>
						<Studies :studies="patient && patient.studies ? patient.studies : []"/>
					</div>
				</div>
			</div>
		</div>		
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	import PersonalInfo from './sections/PersonalInfo'
	import Studies from './sections/Studies'
	export default {

		name: 'Profile',
		props: {
			user_id: {
				type:  		[String, Number],
				default: 	null,
				required: 	true
			}
		},
		components: {
			PersonalInfo,
			Studies
		},
		data () {
			return {
				patient: 	null
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetPatient()
			{
				this.StartLoading()

				window.api.call('post', '/get-patient', {
						user_id: 			this.user_id,
						organization_id: 	this.organization_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.patient  	= data.patient
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			SavePatient(patient)
			{
				this.StartLoading()

				window.api.call('post', '/save-patient', patient)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 	'success',
								title: 	'Patient info successfully saved',
								toast:  true,
								position: 'top',
								timer:  3000,
								showConfirmButton: false,
							})

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Oopss...',
								text: 	'An error has occurred on save this patient, contact support.'
							})
						}
					})
					.finally(() => {
						this.GetPatient()
						this.FinishLoading()
					})

			},
			GetAge(birth_date)
			{
				return window.helpers.GetAge(birth_date)
			}
		},
		beforeMount()
		{
			this.GetPatient()
		}
	}
</script>

<style lang="css" scoped>

	.patient-profile .avatar
	{
		border: 1px solid #bbb;
	    width: 150px;
	    height: 150px;
	    border-radius: 1rem;
	    background-color: #ccc;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}

</style>