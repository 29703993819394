<template>
		<div class="container">
		<div class="row justify-content-between align-items-end">
			<div class="col-12 text-md-center">
				<div class="d-block mx-auto mb-4 max-500 pr-6 pr-md-0">
					<span>User</span>
					<h4 class="font-24 font-md-32 m-0">Your profile data</h4>
				</div>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12 col-md-8">
				<div class="nav-wrapper position-relative mb-2">
					<ul class="nav nav-pills nav-fill flex-column flex-md-row" role="tablist" id="personal-tab">
						<li class="nav-item" role="presentation">
							<a 
								class="nav-link mb-sm-3 mb-md-0 active" 
								data-bs-toggle="tab" 
								href="#personal-info"
								id="personal-info-tab"
							>
								Personal Info
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a 
								class="nav-link mb-sm-3 mb-md-0" 
								data-bs-toggle="tab" 
								href="#change-password"
								id="change-password-tab"
							>
								Change your password
							</a>
						</li>
						<li class="nav-item" role="presentation">
							<a 
								class="nav-link mb-sm-3 mb-md-0" 
								data-bs-toggle="tab" 
								href="#email"
								id="email-tab"
							>
								E-mail
							</a>
						</li>
					</ul>
				</div>

				<div class="tab-content mb-4" id="personal-content">
					<div id="personal-info" class="tab-pane fade show active" role="tabpanel" aria-labelledby="personal-info-tab">
						<PersonalInfo/>
					</div>

					<div id="change-password" class="tab-pane fade" role="tabpanel" aria-labelledby="change-password-tab">
						<Password/>
					</div>

					<div id="email" class="tab-pane fade" role="tabpanel" aria-labelledby="email-tab">
						<Email/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Email from './sections/Email'
	import Password from './sections/Password'
	import PersonalInfo from './sections/PersonalInfo'
	export default {

		name: 'Profile',
		components: 	{
			Email,
			Password,
			PersonalInfo
		},
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>