import router from '../../routes'

const GetAuthUser = ({ commit }) => {
	return window.api.call('get', '/get-auth-user')
        .then( async ({data}) => {
        	if(data.response)
        	{
	            commit('UPDATE_USER', data.user)
				localStorage.setItem('user', JSON.stringify(data.user))
				commit('UPDATE_ROLES', data.roles)
				localStorage.setItem('roles', JSON.stringify(data.roles))
				commit('UPDATE_ORGANIZATIONS', data.organizations)
				localStorage.setItem('organizations', JSON.stringify(data.organizations))

				window.Echo.RunPrivate()
        	}

        })

}

const GetAuthorizationBridgeData = ({ commit, dispatch }, payload) => {

	return new Promise((resolve, reject) => {

		window.axios.post('/get-authorization-bridge-data', {
				bridge_key: payload
			})
			.then( async ({data}) => {

				if(data.response)
				{
					const obj = data.decrypted

					commit('UPDATE_USER', obj.user)
					commit('UPDATE_TOKEN', obj.token)
					commit('UPDATE_ORGANIZATIONS', obj.organizations)

					localStorage.setItem('organizations', JSON.stringify(obj.organizations))
					localStorage.setItem('token', obj.token)
				    localStorage.setItem('user', JSON.stringify(obj.user))
				    			    
				    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + obj.token

				    resolve(data)

				}else{

					reject(data)
				}
			})
			.catch(({response}) => {

				reject(response)
			})

	})
}

const Logout = async ({ commit, state, dispatch }) => {

	dispatch('system/StartLoading', null,{
			root: 		true
		})

	if(state.token)
	{
		await window.axios.post('/logout', {
				token: state.token
			})
			.finally(() => {

				dispatch('LogoutContent')

			})
	}

	router.push({ name: 'GoBack' })
	
}

const LogoutContent = ({ commit, state }) => {

	commit('UPDATE_USER', null)
	commit('UPDATE_TOKEN', null)
	commit('UPDATE_ROLES', [])
	commit('UPDATE_ORGANIZATIONS', [])
	commit('UPDATE_ORGANIZATION_SELECTED', null)

	localStorage.removeItem('token')
	localStorage.removeItem('theme')
	localStorage.removeItem('account')
	localStorage.removeItem('roles')
	localStorage.removeItem('organizations')
	localStorage.removeItem('organization_selected_id')
}

const SetOrganizationSelected = async ({ commit, dispatch }, payload) => {

	await window.Echo.LeaveOrganization()

	commit('UPDATE_ORGANIZATION_SELECTED', payload.organization.id)

	localStorage.setItem('organization_selected_id', payload.organization.id)

	window.Echo.RunOrganization()

	dispatch('notification/GetMyNotifications', null,{
			root: 		true
		})

	if(router.currentRoute.name != 'Home')
	{
		router.push({ name: 'Home' })
	}
}

const AddOnlineAccount = ({ commit }, payload) => {
	commit('ADD_ONLINE_ACCOUNT', payload)
}

const RemOnlineAccount = ({ commit }, payload) => {
	commit('REM_ONLINE_ACCOUNT', payload)
}

const ResetOnlineAccount = ({ commit }, payload) => {
	commit('RESET_ONLINE_ACCOUNT', payload)
}

export {
	GetAuthUser,
	GetAuthorizationBridgeData,
	Logout,
	SetOrganizationSelected,
	LogoutContent,
	AddOnlineAccount,
	RemOnlineAccount,
	ResetOnlineAccount
}