<template>
	<svg width="220" height="85" xmlns="http://www.w3.org/2000/svg">
		<g>
			<title>Layer 1</title>
			<text font-style="normal" font-weight="normal" xml:space="preserve" text-anchor="start" font-family="'Montserrat'" font-size="42" id="svg_1" y="49.5" x="70.5" stroke-width="1" stroke="#000" fill="#ccc">vision</text>
			<text font-weight="normal" xml:space="preserve" text-anchor="start" font-family="'Montserrat'" font-size="40" id="svg_2" y="49" x="25" stroke-width="3" stroke="#1d58a5" fill="#1d58a5">er</text>
			<text xml:space="preserve" text-anchor="start" font-family="'Montserrat'" font-size="18" id="svg_3" y="68.5" x="90" stroke-width="0.4" stroke="#000" fill="#242e4c">smart find.</text>
		</g>
	</svg>
</template>

<script>
	export default {

		name: 'LogoSVG',

		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>