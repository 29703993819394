<template>
	<form @submit.prevent="Save">
		<div class="row">
			<div class="col-12 col-md-10">
				<div class="mb-3">
					<label>Name</label>
					<input 
						type="text" 
						v-model="v$.form.name.$model"
						class="form-control"
						:class="{ 'is-invalid': v$.form.name.$error }"
					>
					<div class="invalid-feedback">
						Required
					</div>
				</div>
			</div>
			<div class="col-12 col-md-2">
				<div class="mb-3">
					<label>Sex</label>
					<select
						v-model="v$.form.sex.$model"
						class="form-select"
						:class="{ 'is-invalid': v$.form.sex.$error }"
					>
						<option value="">Select a sex...</option>
						<option value="F">Female</option>
						<option value="M">Male</option>
					</select>
					<div class="invalid-feedback">
						Required
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="mb-3">
					<label>Social name</label>
					<input 
						type="text" 
						v-model="form.social_name"
						class="form-control"
					>
				</div>
			</div>
			<div class="col-12 col-md-2">
				<div class="mb-3">
					<label>Gender</label>
					<input
						v-model="form.gender"
						class="form-control"
					>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>Document</label>
					<input
						v-model="form.document"
						class="form-control"
					>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="mb-3">
					<label>Email</label>
					<input 
						type="email" 
						v-model="v$.form.email.$model"
						:class="{ 'is-invalid': v$.form.email.$error }"
						class="form-control"
					>
					<div class="invalid-feedback">
						Must be a valid email
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>Phone</label>
					<input 
						type="tel" 
						v-model="form.phone"
						class="form-control"
						v-mask="['+## (###) ####-####', '+## (###) #####-####', '+## (###) ###-#########']"
					>
				</div>				
			</div>
			<div class="col-12 col-md-2">
				<div class="mb-3">
					<label>Birth date</label>
					<input 
						type="date" 
						v-model="v$.form.birth_date.$model"
						class="form-control"
						:class="{ 'is-invalid': v$.form.birth_date.$error }"
					>
					<div class="invalid-feedback">
						Required
					</div>
				</div>				
			</div>
			<div class="col-12 col-md-10">
				<div class="mb-3">
					<label>Address</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.address"
					>
				</div>				
			</div>
			<div class="col-12 col-md-2">
				<div class="mb-3">
					<label>Number</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.number"
					>
				</div>				
			</div>
			<div class="col-12 col-md-8">
				<div class="mb-3">
					<label>Complement</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.complement"
					>
				</div>				
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>Zip code</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.zip_code"
					>
				</div>				
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>District</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.district"
					>
				</div>				
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>City</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.city"
					>
				</div>				
			</div>
			<div class="col-12 col-md-4">
				<div class="mb-3">
					<label>State</label>
					<input 
						type="text"
						class="form-control"
						v-model="form.state"
					>
				</div>				
			</div>
			<div class="col-12">
				<div class="mb-3">
					<label>Observation</label>
					<textarea
						class="form-control"
						v-model="form.observation"
						rows="3"
					>						
					</textarea>
				</div>
			</div>
			<div class="col-12 mt-3 text-right">
				<button
					type="submit"
					class="btn btn-success px-5 text-white"
				>
					Save
				</button>
			</div>
		</div>
	</form>
</template>

<script>
	import useVuelidate from '@vuelidate/core'
	import { required, email } from '@vuelidate/validators'
	import { mapGetters } from 'vuex'
	export default {

		name: 'PersonalInfo',
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		emits: {
			submit: 	{
				type:  		Object
			}
		},
		props: {
			patient: 	{
				type: 		Object,
				default: 	null
			}
		},
		data () {
			return {
				countries: 		window.countries,
				form: 			{
					name: 				'',
					birth_date: 		'',
					sex: 				'',
					gender: 			'',
					social_name: 		'',
					document: 			'',
					phone: 				'',
					email: 				'',
					address: 			'',
					number: 			'',
					complement: 		'',
					zip_code: 			'',
					district: 			'',
					city: 				'',
					state: 				'',
					country: 			'',
					observation: 		''
				}
			}
		},
		watch: {
			patient: {
				handler(patient)
				{
					if(patient)
					{
						this.form.name 			= patient.name ?? ''
						this.form.phone 		= patient.phone ?? ''

						const extra  			= patient.link.extra

						this.form.sex 			= extra.sex ?? extra.PatientSex ?? ''
						this.form.birth_date 	= extra.birth_date ?? extra.PatientBirthDate ?? ''
						this.form.email 		= extra.email ?? ''
						this.form.gender 		= extra.gender ?? ''
						this.form.social_name 	= extra.social_name ?? ''
						this.form.document 		= extra.document ?? ''
						this.form.address 		= extra.address ?? ''
						this.form.number 		= extra.number ?? ''
						this.form.complement 	= extra.complement ?? ''
						this.form.district 		= extra.district ?? ''
						this.form.city 			= extra.city ?? ''
						this.form.state 		= extra.state ?? ''
						this.form.zip_code 		= extra.zip_code ?? ''
						this.form.observation 	= extra.observation ?? ''
						
					}
				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id'
			])
		},
		validations()
		{
			return {
				form: {
					name: 	{
						required
					},
					email: {
						email
					},
					birth_date: {
						required
					},
					sex: {
						required
					}
				}
			}
		},
		methods: {
			Save()
			{
				this.v$.form.$touch()

				if(!this.v$.form.$invalid)
				{
					this.$emit('submit', {
						patient_id: 		this.patient.id,
						organization_id: 	this.organization_selected_id,
						...this.form
					})
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>