<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-block mx-auto mb-4 pb-md-2">
					<span>Audits</span>
					<h4 class="m-0 font-24 font-md-32">All audits</h4>
				</div>
			</div>
		</div>
		<div class="mb-3 row">
			<div class="col">
				<input
					type="search"
					class="form-control"
					placeholder="Search..."
					v-model="filters.search"
				>
			</div>
			<div class="col-auto">
				<button
					type="button"
					class="btn btn-tertiary px-3"
					@click="NewAudit()"
				>
					new audit
				</button>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col-12">
				<DataTables
					:headers="headers"
					v-model="audits"
					:search="filters.search"
					:paginate="true"
					:excel_filter="true"
				>
					<template v-slot:body="{ item }">
						<td>
							<div class="td-content">
								#{{item.id}}
							</div>
						</td>
						<td>
							<div class="td-content">
								{{item.user.name}}
							</div>
						</td>
						<td>
							<div class="td-content">
								{{TranslateStep(item.step)}}
							</div>
						</td>
						<td>
							<div class="td-content">
								{{item.created_at_parse}}
							</div>
						</td>
						<td>
							<div class="td-content justify-content-end">
								<button
									type="button"
									class="btn btn-tertiary btn-sm px-5"
									@click="ShowItem(item)"
								>
									show <i class="bi bi-search ms-2"></i>
								</button>
							</div>
						</td>
					</template>
				</DataTables>
			</div>
		</div>

		<div
			class="modal fade"
			id="audit-modal"
			tabindex="-1"
			aria-labelledby="audit-modal"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-xl">
			    <div class="modal-content" v-if="audit">
			    	<div class="modal-header d-flex justify-content-between">
			    		<h5 class="modal-title">
							Audit #<b>{{ audit.id }}</b> - <small>By {{ audit.user.name ?? 'Unknown' }}</small>
						</h5>
						<span class="text-danger">
							<a href="javascript:;" @click.prevent="DeleteAudit(audit.id)">
								<i class="bi bi-trash"></i>
							</a>
						</span>
			    	</div> 

			        <div class="modal-body">

			            <div class="d-flex justify-content-center align-items-center">
						    <div class="progresses" v-if="audit.step != 7">
						        <div class="steps" :class="{ 'concluded': audit.step >= 1 }">
						            <span>
						                <div class="spinner-border spinner-border-sm" role="status" v-if="audit.step == 1">
											<span class="visually-hidden">Loading...</span>
										</div>
						                <i class="bi bi-file-spreadsheet" v-else></i>
						            </span>
						            <span class="position-absolute step-description">
						                {{ TranslateStep(1) }}
						            </span>
						        </div>
						        <span class="line" :class="{ 'concluded': audit.step >= 2 }"></span>
						        <div class="steps" :class="{ 'concluded': audit.step >= 2 }">
						            <span>
						                <div class="spinner-border spinner-border-sm" role="status" v-if="audit.step == 2">
											<span class="visually-hidden">Loading...</span>
										</div>
						                <i class="bi bi-file-earmark-medical" v-else> </i>
						            </span>
						            <span class="position-absolute step-description">
						                {{ TranslateStep(2) }}
						            </span>
						        </div>
						        <span class="line" :class="{ 'concluded': audit.step >= 3 }"></span>
						        <div class="steps" :class="{ 'concluded': audit.step >= 3 }">
						            <span>
						                <div class="spinner-border spinner-border-sm" role="status" v-if="audit.step == 3">
											<span class="visually-hidden">Loading...</span>
										</div>
						                <i class="bi bi-gear" v-else></i>
						            </span>
						            <span class="position-absolute step-description">
						                {{ TranslateStep(3) }}
						            </span>
						        </div>
						        <span class="line" :class="{ 'concluded': audit.step >= 4 }"></span>
						        <div class="steps" :class="{ 'concluded': audit.step >= 4 }">
						            <span>
						                <div class="spinner-border spinner-border-sm" role="status" v-if="audit.step == 4">
											<span class="visually-hidden">Loading...</span>
										</div>
						                <i class="bi bi-cpu" v-else></i>
						            </span>
						            <span class="position-absolute step-description">
						                {{ TranslateStep(4) }}
						            </span>
						        </div>
						        <span class="line" :class="{ 'concluded': audit.step >= 5 }"></span>
						        <div class="steps" :class="{ 'concluded': audit.step >= 5 }">
						            <span>
						                <div class="spinner-border spinner-border-sm" role="status" v-if="audit.step == 5">
											<span class="visually-hidden">Loading...</span>
										</div>
						                <i class="bi bi-pencil" v-else></i>
						            </span>
						            <span class="position-absolute step-description">
						                {{ TranslateStep(5) }}
						            </span>
						        </div>
						        <span class="line" :class="{ 'concluded': audit.step >= 6 }"></span>
						        <div class="steps" :class="{ 'concluded': audit.step == 6 }">
						            <span> <i class="bi bi-check-lg"></i> </span>
						            <span class="position-absolute step-description">
						                {{ TranslateStep(6) }}
						            </span>
						        </div>
						    </div>
						</div>

					    <div class="row">

							<div v-if="audit.step == 1" class="d-grid justify-content-center my-5">

								<div class="d-flex">
									<div class="col-auto">
										<label for="spreadsheet_input">Upload the spreadsheet file</label>
										<input class="form-control" type="file" id="spreadsheet_input" @change="UploadSpreadsheetChange">
									</div>

									<div class="col-auto mt-auto px-4">
										<button
											type="button"
											class="btn btn-success btn-block btn-lg"
											@click="UploadSpreadsheet(audit)"
											:class="{'disabled': !spreadsheet_upload}"
										>
											<i class="bi bi-upload"></i>
											Upload
										</button>
									</div>
								</div> 

							</div>

							<div v-if="audit.step == 2" class="d-grid justify-content-center my-5">
 
								<div class="d-flex">
									
									<div class="col-auto">
										<label for="spreadsheet_input">Upload the dicom file</label>
										<input class="form-control" type="file" id="dicom_input" @change="UploadDicomChange">
									</div> 

									<div class="col-auto mt-auto px-4">
										<button
											type="button"
											class="btn btn-success btn-block btn-lg"
											@click="UploadDicom(audit)"
											:class="{'disabled': !dicom_upload}"
										>
											<i class="bi bi-upload"></i>
											Upload
										</button>
									</div> 

								</div>

								<div class="row mt-3">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="category" value="type"
                                                   :checked="audit.category == 'type'"
                                                   v-model="audit.category"
												   disabled
                                                   @change="SaveAuditProps"
                                                >
                                                Type
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="category" value="subtype"
                                                   :checked="audit.category == 'subtype'"
                                                   v-model="audit.category"
												   disabled
                                                   @change="SaveAuditProps"
                                                >
                                               Subtype
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="category" value="percentage"
                                                   :checked="audit.category == 'percentage'"
                                                   v-model="audit.category"
                                                   @change="SaveAuditProps"
                                                >
                                                Percentage
                                            </label>
                                        </div>
                                    </div>
									<div class="col-6">
										<div class="form-check">
											<input class="form-check-input" type="checkbox" :checked="audit.lower_na == 1 ? 'true' : 'false'" @change="SaveAuditProps" v-model="audit.lower_na" id="lower_na">
											<label class="form-check-label" for="lower_na">
												Peso do NA
											</label>
										</div>
									</div>
								</div>

							</div>

							<div v-if="audit.step == 3" class="row justify-content-center my-5">
								<div class="col-6">
									<p class="text-center">
										At this point, the DICOM images and the spreadsheet sent are compared to organize what will be processed by the AI, and still at this stage the images are already sent to the AI. Just wait for the process to finish.
									</p>

<!--									<AuditStatistics-->
<!--										:audit='audit'									-->
<!--									/>-->
								</div>
							</div>

							<div v-if="audit.step == 4" class="row justify-content-center my-5">
								<div class="col-md-6">
									<p class="text-center ">
										AI is processing the DICOMS and delivering the results. Just wait for this process to finish.	

										{{ GetProgressOfStudies() }}
									</p>

<!--									<AuditStatistics-->
<!--										:audit='audit'									-->
<!--									/>-->
								</div>
							</div> 

							<div v-if="audit.step == 5" class="row justify-content-center my-5">
								<div class="col-12 col-lg-6">
									<p class="text-center ">
										AI has already delivered all the results and now the results are being compared and written. It's almost finishing.
									</p> 

<!--									<AuditStatistics-->
<!--										:audit='audit'									-->
<!--									/>-->
								</div>
							</div>

							<div v-if="audit.step == 6" class="row justify-content-center my-5">
								<div class="col-12 col-xl-9">

									<AuditStatistics
										:audit='audit'									
									/>

									<div class="col-12 text-center">
										<button type="button" class="btn btn-primary px-5" @click='AuditDownload()'>
											<i class="bi bi-download"></i>
											Download
										</button>			
									</div>
								</div>
							</div> 

							<div v-if="audit.step == 7">
								<p class="text-danger my-5 text-center">
									An error as ocurred, this audit has aborted. Reason: {{ audit.error ?? 'No error specified' }}
								</p>
							</div>

					    </div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex' 
import fileDownload from 'js-file-download'
import AuditStatistics from './AuditStatistics.vue'

export default {
	name: 'Audits',
	data() {
		return {
			audit: null,
			audits: [],
			filters: {
				search: ''
			},
			headers: 	[
				{ text: 'Our ID', value: 'id', sortable: true, type: Number },
				{ text: 'User', value: 'user', sortable: true, type: Number },
				{ text: 'Step', value: 'step', sortable: true, type: String },
				{ text: 'Uploaded at', value: 'created_at', sortable: true, type: Date },
				{ text: '', value: null, sortable: false },
			],
			result_item: null,
			spreadsheet_upload: null,
			dicom_upload: null
		}
	},
	components: {
		AuditStatistics
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading', 'LoadingMessage'
		]),
		async LoadAudits(spinner = true) {

			if (spinner) this.StartLoading()

			return window.api.call('post', '/get-audits', {
					organization_id: 		this.organization_selected_id
				})
				.then(({data}) => {
					if(data.response)
					{
						this.audits 		= data.audits
					}
				})
				.finally(() => {
					if (spinner) this.FinishLoading()
				})

		},
		async NewAudit() {

			this.StartLoading()

			window.api.call('post', '/new-audit', {
					organization_id: 		this.organization_selected_id,
					user_id:                this.user.id
				})
				.then(({data}) => {
					if(data.response)
					{
						this.audit 		= data.audit

						this.LoadAudits();

						this.result_item.show();
					}
				})
				.finally(() => {
					this.FinishLoading()
				})

		},
		TranslateStep(step) {

			switch (step) {
				case 0: return 'Audit created';
				case 1: return 'Waiting spreadsheet upload';
				case 2: return 'Waiting dicom upload';
				case 3: return 'Processing spreadsheet';
				case 4: return 'Processing dicom on AI';
				case 5: return 'Writing results';
				case 6: return 'Process concluded';
				case 7: return 'Process failed';
				default: return 'Unknown step'
			}

		},
		ShowItem(item) {
			this.audit 		= item

			this.result_item.show()
		},
		UploadSpreadsheetChange(e) {

			let files = e.target.files || e.dataTransfer.files;

			if (!files.length) return;

			this.spreadsheet_upload = files[0]

		},
		UploadDicomChange(e) {

			let files = e.target.files || e.dataTransfer.files;

			if (!files.length) return;

			this.dicom_upload = files[0]

		},
		async UploadSpreadsheet(audit) {

			const f = this.spreadsheet_upload
			const data = new FormData()

			data.append('file', f, f.name)
			data.append('audit_id', audit.id)

			this.StartLoading()

			this.FileUpload('/audit-upload-spreadsheet', data)

		},
		async UploadDicom(audit) {

			const f = this.dicom_upload
			const data = new FormData()

			data.append('file', f, f.name)
			data.append('audit_id', audit.id)

			this.StartLoading()

			this.FileUpload('/audit-upload-dicom', data)

		},
		async FileUpload(endpoint, form_data) {

			return await window.api.call('post', endpoint, form_data, {
					onUploadProgress: (event) => {

						let percentage 	= Math.round((event.loaded * 100) / event.total)
						console.log(`upload percentage ${percentage}%`)

						if ( percentage < 100 ) { 
							this.LoadingMessage(`Uploading... ${percentage}%`)
						} else { 
							this.LoadingMessage('Analyzing... Keep waiting')
						}

					}
				})
				.then(({data}) => {

					if (data.response) {

						this.audit = data.audit

						this.LoadAudits();

					} else {

						Swal.fire('Ops!', 'An error has ocurred: ' + data.message, 'error')

					}

				})
				.finally((data) => {
					this.FinishLoading() 
				})
				.catch((data) => { 
					console.error(data)
				})

		},
		GetProgressOfStudies() {

			if ( ! this.audit.audit_studies || this.audit.audit_studies.length == 0 ) return ''

			let ready_studies = this.audit.audit_studies.filter(study => study.status == 2) 

			return ready_studies.length + ' of ' + this.audit.audit_studies.length + ' studies'

		},
		async AuditDownload() {

			if ( ! this.audit ) return;

			this.StartLoading()

			window.axios({
				url: '/audit-download/' + this.audit.id,
				method: 'GET',
				responseType: 'blob', 
			})
			.then((response) => { 

				if ( response.status != 200 ) {

					Swal.fire({
						icon: 'error',
						title: 'Ops!',
						text: 'An error ocurred: ' + response.data.message
					})

					return

				}

				fileDownload(response.data, response.headers.file_name);
			})
			.finally(() => {
				this.FinishLoading()
			})


		},
		DeleteAudit(audit_id) {

			Swal.fire({
				title: `Delete audit #${audit_id}?`,
				icon: 'question',
				showConfirmButton: true,
				showCancelButton: true
			})
			.then( ({isConfirmed}) => { 
				
				if ( ! isConfirmed ) return

				this.StartLoading()

				window.axios({
					url: '/audit-delete/' + this.audit.id,
					method: 'DELETE'
				})
				.then((response) => { 

					if ( response.status != 200 ) {

						Swal.fire({
							icon: 'error',
							title: 'Ops!',
							text: 'An error ocurred: ' + response.data.message
						})

						return

					}

					this.result_item.hide()

					this.LoadAudits()

				})
				.finally(() => {
					this.FinishLoading()
				})
				
			})

		},
		SaveAuditProps(e) {
			
			if (!this.audit) return

			window.api.call('post', 'audit-save-props', {
				audit_id: this.audit.id,
				lower_na: this.audit.lower_na,
        category: this.audit.category
			})

		}
	},
	watch: {
		updated_audit: {
			deep: true,
			immediate: true,
			handler: function (val, oldVal) {

				if ( ! val ) return

				this.LoadAudits(false)

				if ( this.audit && val.id == this.audit.id ) {
 
					this.audit = this.updated_audit

				}

			}
		},
	},
	computed: {
		...mapGetters('auth', [
			'organization_selected_id', 'token', 'user'
		]),
		...mapGetters('system', [
			'updated_audit'
		])
	},
	mounted()
	{
		this.result_item 		= new window.bootstrap.Modal(document.getElementById('audit-modal'))
	},
	created() {
	},
	beforeMount() {

		this.LoadAudits()

	},
}
</script>

<style scoped>

.progresses {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
}

.concluded {
    background: #63d19e !important;
}

.line {
    width: 72px;
    height: 6px;
    background: #858585;
}

.steps {
    display: flex;
    background-color: #636363;
    color: #fff;
    font-size: 14px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%
}

.step-description {
    color: black;
    width: 100px;
    text-align: center;
    top: 62px;
    font-size: x-small;
}
</style>