<template>
	<div class="uploader shadow-soft" v-if="!uploading">
		<input 
			ref="input_file" 
			type="file" 
			class="file-input" 
			multiple
			@change="AddFilesToUploadQueue($event)"
		>
		<div class="uploader-content">
			<span>Select or drag files here</span>
			<br/>
			<span>[ <b>ZIP OR DICOM ONLY</b> ]</span>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import  Swal from 'sweetalert2'
	export default {

		name: 'Uploader',
		emits: {
			queue: {
				type: Array
			},
			uploading: {
				type: Boolean
			},
			complete: {
				type: Boolean
			}
		},
		data () {
			return {
				queue: 			[],
				uploaded: 		[],
				uploading: 		false,
				complete: 		false,
				instance_token: null
			}
		},
		watch: {
			uploading: {
				handler(val)
				{
					this.$emit('uploading', val)
				},
				immediate: true
			},
			complete: {
				handler(val)
				{
					this.$emit('complete', val)
				},
				immediate: true
			},
			queue: {
				handler(arr)
				{
					if(this.uploading)
					{
						this.$emit('queue', arr)
					}
				},
				deep: true
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id'
			])
		},
		beforeMount()
		{
			this.instance_token 		= window.helpers.UniqueToken()
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'LoadingMessage'
			]),
			async StartUpload()
			{
				if(this.queue.length > 0)
				{
					this.uploading 	= true
					this.complete 	= false

					this.StartLoading()

					let total_entries = this.queue.length 

					for( const [index, f] of this.queue.entries())
					{
						if(f.percentage === 100)
						{
							continue
						}

						try
						{
							const data 					= new FormData()
							data.append('file', f.file, f.name)
							data.append('organization_id', this.organization_selected_id)
							data.append('instance_token', this.instance_token)

							this.queue[index].current 	= true

							await window.api.call('post', '/upload-file', data, {
									onUploadProgress: (event) => {
										let percentage 	= Math.round((event.loaded * 100) / event.total)

										this.queue[index].percentage 	= percentage

										this.LoadingMessage(`Uploading ${index + 1} of ${total_entries}: ${percentage}%`)
									}
								})
								.then(({data}) => {

									if(data.response)
									{
										//
									} else if (data.message == 'existent_files') {

										let files = window._.uniq(data.existent_files)

										let parsed_files = files.join(', ')

										Swal.fire({
											title: `<p class="d-flex m-0">Some files has been already uploaded. </p><p class="m-0">Accession numbers: <b>${parsed_files}.</b></p>`,
											icon: 'info',
											toast: true,
											position: 'top-end',
											showConfirmButton: false,
											timer: 15000,
											timerProgressBar: true,
										})
										
									}else{

										this.queue[index].error 	= true
									}
								})
								.finally(() => {
									this.queue[index].completed = true
									this.queue[index].current 	= false
								})
							}
							catch(e)
							{
								console.log(e)
							}
					}

					this.complete = true

					this.FinishLoading()
				}
			},
			ClearQueue()
			{
				this.queue 		= []

				this.$emit('queue', this.queue)

				this.$refs.input_file.value = null
			},
			async AddFilesToUploadQueue(e)
			{
				const files 		= Array.from(e.target.files)
				let position 		= this.queue.length

				await files.forEach(file => {

					position++

					this.queue.push({
						id: 			window.helpers.MD5([file, position]),
						position: 		position,
						percentage: 	0,
						current: 		false,
						error: 			false,
						completed: 		false,
						size: 			`${parseInt(file.size / 1024)}kb`,
						name: 			file.name,
						file: 			file
					})
				})

				window._.sortBy(this.queue, ['position'])

				this.$emit('queue', this.queue)

				this.$refs.input_file.value = null
			},
			async RemoveFileFromQueue(file_id)
			{
				let position 	= 0

				this.queue 		= await window._.sortBy(this.queue.filter( f => {

					if(f.id != file_id)
					{
						position++

						f.position = position

						return true
					}

					return false

				}), ['position'])

				this.$emit('queue', this.queue)
			}
		}
	}
</script>

<style lang="css" scoped>
	.uploader
	{
		width: 100%;
		height: 200px;
		border-radius: 1rem;
		background-color: #fafafa;
		border: 1px dashed #909090;
		transition: all .2s ease-in-out;
		position: relative;
		align-items: center;
	}

	.uploader:hover
	{
		background-color: #eaeaea;
	}

	.uploader .uploader-content
	{
		height: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
        font-size: 18px;
    	font-weight: 600;
	}

	.uploader .file-input
	{
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		cursor: pointer;
		opacity: 0;
	}


</style>