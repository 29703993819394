const ADD_RUNNING_REQUEST = (state) => {
	state.running_requests++
}

const REM_RUNNING_REQUEST = (state) => {
	state.running_requests--
}

const UPDATED_AUDIT = (state) => {
	state.updated_audit
}

export {
	ADD_RUNNING_REQUEST,
	REM_RUNNING_REQUEST,
	UPDATED_AUDIT
}