import Vuex from './vuex'

class Api {
    constructor () {}

    call (requestType, url, data = null, opts = {}) {

    	//const axios 		= window.axios.create()

    	//delete axios.defaults.headers.common["X-Socket-Id"]

	    return new Promise((resolve, reject) => {
	        window.axios[requestType](url, data, opts)
	            .then(response => {
	                resolve(response)
	            })
	            .catch(({response}) => {
	                
					try {
						if ([400, 401, 418].indexOf(response.status) > -1) 
						{	               
							Vuex.dispatch('auth/Logout')
								.finally(() => {
									reject(response)
								})
						}
					} catch (error) {
						console.error(error);
					}

	                resolve(response)
	            })
	    });
	}
}

export default Api;