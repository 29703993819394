import { createStore } from 'vuex'
import auth from './store/auth'
import system from './store/system'
import notification from './store/notification'

export default createStore({
	modules: {
		auth,
		system,
		notification
	}
})