<template>
	<p>awaiting...</p>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Index',
		props: 	{
			token: 	{
				type: 	String,
				default: null
			}
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'check'
			])
		},
		methods: {
			...mapActions('auth', [
				'GetAuthorizationBridgeData', 'LogoutContent'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GoBack()
			{
				window.location.href 	= process.env.VUE_APP_LANDING_PAGE_URL
			}
		},
		async beforeMount()
		{
			this.StartLoading()

			await this.LogoutContent()
		},
		async mounted()
		{
			if(!this.token)
			{
				this.GoBack()
			}

			if(this.check)
			{
				this.$router.push({ name: 'Home' })
			}

			await this.GetAuthorizationBridgeData(this.token)
				.then(async (data) => {

					Swal.fire({
						toast:  	true,
						title:		'Login successfully.',
						icon: 		'success',
						timer: 		3000,
						timerProgressBar: true,
						showConfirmButton: false,
						position: 	'top',
					})

					window.Echo.RunPrivate()
					
					this.$router.push({ name: 'Home' })
				})
				.catch((response) => {

					this.GoBack()
				})

			this.FinishLoading()
		}
	}
</script>

<style lang="css" scoped>
</style>