import CryptoJS from 'crypto-js'

class Helpers {
	constructor () {}

	GetAge(birth_date)
	{
		let a 	= window.moment()
		let b 	= window.moment(birth_date)

		const years = a.diff(b, 'year')
		b.add(years, 'years')

		const months = a.diff(b, 'months')
		b.add(months, 'months')

		const days = a.diff(b, 'days')

		return years + ' years, ' + months + ' months and ' + days + ' days'
	}

	GetStudyStatusObject(status)
	{
		status 		= parseInt(status)
		
		switch(status)
		{
			case 1: //READY_TO_CLASSIF_STATUS
				return {
					text: 	'READY FOR CLASSIFICATION',
					color: 	'tertiary'
				}

			case 2: //QUEUED_ON_API_UPLOAD_STATUS
				return {
					text: 	'AWAITING UPLOAD TO AI',
					color: 	'warning'
				}

			case 3: //RUNNING_API_CLASSIFICATION_STATUS:
				return {
					text: 	'CLASSIFICATION IN PROGRESS',
					color: 	'light text-dark'
				}

			case 4: //CLASSIFIED_STATUS
				return {
					text: 	'CLASSIFIED SUCCESSFULLY',
					color: 	'success text-white'
				}

			default:
				return {
					text: 	'UNDEFINED',
					color: 	'dark'
				}

		}
	}

	UniqueToken()
	{
		return this.MD5(new Date())
			.replace(/[^A-Za-z0-9\s!?]/g, '')
	}

	MD5(content)
	{
		const secret 	= process.env.VUE_APP_SECRET_KEY
		content 		= JSON.stringify(content)

		return CryptoJS.AES.encrypt(content, secret)
			.toString()
	}

	DecryptMD5(encrypted)
	{
		const secret 	= process.env.VUE_APP_SECRET_KEY
		const bytes 	= CryptoJS.AES.decrypt(encrypted, secret)

		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	}

	ValidateCPF (cpf) 
	{
		cpf 		= cpf.replace(/[^\d]+/g,'');

		if(cpf == '')
		{
			return false;
		} 

		let num, digit, sum, i, res, same_digits;

		same_digits = 1;

		if (cpf.length < 11)
		{
			return false;
		}

		for (i = 0; i < cpf.length - 1; i++)
		{
			if (cpf.charAt(i) != cpf.charAt(i + 1))
			{
				same_digits = 0;
				break;
			}
		}

		if (!same_digits)
		{
			num 		= cpf.substring(0,9);
			digit 		= cpf.substring(9);
			sum 		= 0;

			for (i = 10; i > 1; i--)
			{
				sum += num.charAt(10 - i) * i;
			}

			res 		= sum % 11 < 2 ? 0 : 11 - sum % 11;

			if (res != digit.charAt(0))
			{
				return false;
			}

			num 		= cpf.substring(0,10);
			sum 		= 0;

			for (i = 11; i > 1; i--)
			{
				sum += num.charAt(11 - i) * i;
			}

			res 	= sum % 11 < 2 ? 0 : 11 - sum % 11;

			if (res != digit.charAt(1))
			{
				return false;
			}

			return true;

		}else{

			return false;
		}
	}

	ValidateCNPJ (cnpj)
	{
		cnpj 		= cnpj.replace(/[^\d]+/g,'');

		if(cnpj == '')
		{
			return false;
		} 

		if (cnpj.length != 14)
		{
			return false;
		}

		if (cnpj == "00000000000000" || 
			cnpj == "11111111111111" || 
			cnpj == "22222222222222" || 
			cnpj == "33333333333333" || 
			cnpj == "44444444444444" || 
			cnpj == "55555555555555" || 
			cnpj == "66666666666666" || 
			cnpj == "77777777777777" || 
			cnpj == "88888888888888" || 
			cnpj == "99999999999999")
		{
			return false;
		}

		let i;
		let size 		= cnpj.length - 2;
		let num 		= cnpj.substring(0,size);
		let digit 		= cnpj.substring(size);
		let sum 		= 0;
		let pos 		= size - 7;

		for (i = size; i >= 1; i--) 
		{
			sum 		+= num.charAt(size - i) * pos--;

			if (pos < 2)
			{
				pos 	= 9;
			}
		}

		let res 			= sum % 11 < 2 ? 0 : 11 - sum % 11;

		if (res != digit.charAt(0))
		{
			return false;
		}
		
		size 			= size + 1;
		num 			= cnpj.substring(0,size);
		sum 			= 0;
		pos 			= size - 7;

		for (i = size; i >= 1; i--) 
		{
			sum 		+= num.charAt(size - i) * pos--;
			if (pos < 2)
			{
				pos 		= 9;
			}
		}

		res 			= sum % 11 < 2 ? 0 : 11 - sum % 11;

		if (res != digit.charAt(1))
		{
			return false;
		}
		
		
		return true;
	}

	RemoveAccent (str_to_replace)
	{

		let str_with_accent 	= "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ"
		let str_without_accent 	= "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC"
		let newest_str 			= ""

		for (let i = 0; i < str_to_replace.length; i++) 
		{
			if (str_with_accent.indexOf(str_to_replace.charAt(i)) != -1) {
				newest_str += str_without_accent.substr(str_with_accent.search(str_to_replace.substr(i, 1)), 1);
			} else {
				newest_str += str_to_replace.substr(i, 1);
			}
		}

		return newest_str;
	}

	Slugify(string)
	{

		let slugged_string 		= string.toLowerCase()
			.replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
			.replace(/[èÈéÉêÊëË]+/g, 'e')
			.replace(/[ìÌíÍîÎïÏ]+/g, 'i')
			.replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
			.replace(/[ùÙúÚûÛüÜ]+/g, 'u')
			.replace(/[ýÝÿŸ]+/g, 'y')
			.replace(/[ñÑ]+/g, 'n')
			.replace(/[çÇ]+/g, 'c')
			.replace(/[ß]+/g, 'ss')
			.replace(/[Ææ]+/g, 'ae')
			.replace(/[Øøœ]+/g, 'oe')
			.replace(/[%]+/g, 'pct')
			.replace(/\s+/g, '-')
			//.replace(/[^\w\-]+/g, '')
			//.replace(/\-\-+/g, '-')
			.replace(/^-+/g, '')
			.replace(/-+$/g, '');

		return slugged_string
	}

	SlugifyWithDiff(string)
	{
		let date 				= new Date()
		let diff 				= String(date.getMinutes()) + String(date.getHours()) + String(date.getSeconds()) + String(date.getMonth())

		let slugged_string 		= this.Slugify(string)

		return diff + '-' + slugged_string
	}

	ParseDate(string)
	{
		if(!string)
		{
			return ''
		}

		if(string.length == 27)
		{
			string 	= this.ParseISODate(string)
		}

		let split 	= string.split(' ')

		let date    = split[0].split('-')
		let time    = split[1].split(':')

		return `${date[2]}/${date[1]}/${date[0]} at ${time[0]}:${time[1]}`
	}

	ParseISODate(date)
	{
		let data 	= new Date(date)

		return `${data.getFullYear()}-${(data.getMonth() + 1).toString().padStart(2,'0')}-${data.getDate().toString().padStart(2,'0')} ${data.getHours().toString().padStart(2,'0')}:${data.getMinutes().toString().padStart(2,'0')}:${data.getSeconds().toString().padStart(2,'0')}`
	}

	FormatDate(date) 
	{
		if (!date) return null

		const [year, month, day] = date.split('-')

		return `${day}/${month}/${year}`
	}

	Base64ToFile(base64, mime, name) 
	{
		mime 			= mime || ''
		let sliceSize 	= 1024

		base64 			= base64.replace('data:image/png;base64,','');
		base64 			= base64.replace('data:image/webp;base64,','');
		base64 			= base64.replace('data:image/jpg;base64,','');
		base64 			= base64.replace('data:image/jpeg;base64,','');

		let byteChars 	= window.atob(base64)
		let byteArrays 	= []

		for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) 
		{
			let slice 			= byteChars.slice(offset, offset + sliceSize)

			let byteNumbers 	= new Array(slice.length)

			for (let i = 0; i < slice.length; i++) 
			{
				byteNumbers[i] 			= slice.charCodeAt(i)
			}

			let byteArray 		= new Uint8Array(byteNumbers)

			byteArrays.push(byteArray)
		}

		return new File(byteArrays , name , { type: mime })
	}
}

export default Helpers;