<template>
	<div>
		<div class="row justify-content-end">
			<div class="col-12">
				<label>Your current password</label>
				<input 
					v-model="v$.form.current_password.$model"
					:class="{ 'is-invalid': v$.form.current_password.$error }"
					type="password" 
					class="form-control"
					ref="current_password"
				>
				<div class="invalid-feedback">
					Required
				</div>
			</div>
			<div class="col-12 mt-3">
				<label>Your new password</label>
				<input 
					v-model="v$.form.password.$model"
					:class="{ 'is-invalid': v$.form.password.$error }"
					type="password" 
					class="form-control"
					ref="password"
				>
				
				<div class="invalid-feedback" v-if="v$.form.password.minLength.$invalid">
					Must be longer than 6 characters
				</div>
				<div class="invalid-feedback" v-if="v$.form.password.DifferentOfCurrent.$invalid">
					It needs to be different from the current password
				</div>
				<div class="invalid-feedback" v-if="v$.form.password.required.$invalid">
					Required
				</div>
			</div>
			<div class="col-12 mt-3">
				<label>Retype your new password</label>
				<input 
					v-model="v$.form.password_confirmation.$model"
					:class="{ 'is-invalid': v$.form.password_confirmation.$error }"
					type="password" 
					class="form-control"
					ref="password_confirmation"
				>
				<div class="invalid-feedback">
					You must enter the same password
				</div>
			</div>
			<div class="col-md-auto mt-3">
				<button
					type="button"
					class="btn btn-success btn-block px-5 text-white"
					@click="Save"
				>
					Save
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	import useVuelidate from '@vuelidate/core'
	import { required, sameAs, minLength } from '@vuelidate/validators'

	const notSameAs = (param) => (value, vm) => {
		return value != vm.form[param]
	}

	export default {

		name: 'Password',
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				form: {
					current_password:	    '',
					password: 				'',
					password_confirmation: 	''
				}			
			}
		},
		validations()
		{
			return {
				form: {
					current_password:	{
						required
					},
					password: 	{
						required,
						minLength: minLength(6),
						DifferentOfCurrent: notSameAs('current_password')
					},
					password_confirmation:	{
						SameAsPassword: sameAs(this.form.password)
					}
				}			
			}
		},
		computed: {
			...mapGetters('auth', [
				'user'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'Logout'
			]),
			Save()
			{
				this.v$.form.$touch()

      			if (!this.v$.form.$invalid)
      			{
      				this.StartLoading()
      				
      				window.api.call('post', '/change-my-password', this.form)
      					.then(({data}) => {
      						if(data.response)
      						{
      							Swal.fire({
      								icon: 		'success',
      								title: 		'Password changed successfully',
      								text: 		'Now, you need to login again, OK ?'
      							})
      							.then(() => {
      								this.Logout()
      							})

      						}else{

      							Swal.fire({
      								icon: 		'error',
      								title: 		'Oops...',
      								text: 		'An error has occurred on trying change your password, contact support.'
      							})
      						}
      					})
      					.finally(() => {

      						this.ClearForm()
      						this.FinishLoading()
      					})
      			}
			},
			ClearForm()
			{
				this.form.current_password 			= ''
				this.form.password 					= ''
				this.form.password_confirmation 	= ''
			}
		}
	}
</script>

<style lang="css" scoped>
</style>