<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-block mx-auto mb-4 pb-md-2">
					<span>AI Classifications</span>
					<h4 class="m-0 font-24 font-md-32">All Results</h4>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12 col-md-auto mb-3 mb-md-0 text-center">
				<div class="btn-group" role="group" aria-label="filter">
					<button
						type="button"
						class="btn btn-tertiary"
						:class="{ 'active': filters.mode == 'all' }"
						@click="filters.mode = 'all'"
					>
						All
					</button>
					<button
						type="button"
						class="btn btn-tertiary"
						:class="{ 'active': filters.mode == 'suspect' }"
						@click="filters.mode = 'suspect'"
					>
						Suspect
					</button>
					<button
						type="button"
						class="btn btn-tertiary"
						:class="{ 'active': filters.mode == 'doubtful' }"
						@click="filters.mode = 'doubtful'"
					>
						Doubtful
					</button>
					<button
						type="button"
						class="btn btn-tertiary"
						:class="{ 'active': filters.mode == 'normal' }"
						@click="filters.mode = 'normal'"
					>
						Normal
					</button>
				</div>
			</div>
			<div class="col-12 col-md">
				<input
					type="search"
					class="form-control"
					placeholder="Search..."
					v-model="filters.search"
				>
			</div>
			<div class="col-md-auto">
				<button type="button" class="btn btn-tertiary" @click="filters.more = !filters.more">
					Filter <i class="bi bi-filter ms-2"></i>
				</button>
			</div>
		</div>
		<div class="row justify-content-end" v-show="filters.more">
			<div class="col-12 col-md-auto">
				<label>Show studies</label>
				<select class="form-select" v-model="items_per_page">
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="75">75</option>
					<option value="all">All studies</option>
				</select>
			</div>
			<div class="col-12 col-md">
				<label>Filter by</label>
				<select class="form-select" v-model="filters.date_type">
					<option value="study_date">Study date</option>
					<option value="uploaded_at">Uploaded at</option>
				</select>
			</div>
			<div class="col-12 col-md-3">
				<label>Start at</label>
				<input type="date" class="form-control" v-model="filters.start_at">
			</div>
			<div class="col-12 col-md-3">
				<label>Ends at</label>
				<input type="date" class="form-control" v-model="filters.ends_at">
			</div>
			<div class="col-12 col-md-2">
				<label class="row">Laterality columns</label>
				<input type="checkbox" class="row form-check-input" v-model="filters.laterality"> 
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-12">
				<DataTables
					:headers="headers"
					v-model="filtered_studies"
					:search="filters.search"
					:paginate="true"
					:excel_filter="true"
					:items_per_page='items_per_page'
				>
					<template v-slot:body="{ item }">
						<td>
							<div class="td-content">
								#{{item.id}}
							</div>
						</td>
						<td>
							<div class="td-content">
								{{item.accession_number}}
							</div>
						</td>
						<td>
							<div class="td-content">
								{{item.patient_name}}
							</div>
						</td>
						<td>
							<div class="td-content">
								<div
									class="badge text-uppercase"
									:class="{
										'bg-danger': item.result_type == 'suspect',
										'bg-success': item.result_type == 'normal',
										'bg-warning': item.result_type == 'doubtful'
									}"
								>
									{{item.result_type}}
								</div>
							</div>
						</td>
						<td v-if="filters.laterality">
							<div class="td-content">
								{{ item.biggest_cat_left }}
							</div>
						</td>
						<td v-if="filters.laterality">
							<div class="td-content">
								{{ item.biggest_cat_right }}
							</div>
						</td>
						<td>
							<div class="td-content">
								{{item.created_at}}
							</div>
						</td>
						<td>
							<div class="td-content justify-content-end">
								<button
									type="button"
									class="btn btn-tertiary btn-sm px-5"
									@click="ShowResult(item)"
								>
									show <i class="bi bi-search ms-2"></i>
								</button>
							</div>
						</td>
					</template>
				</DataTables>
			</div>
		</div>


		<div
			class="modal fade"
			id="result-modal"
			tabindex="-1"
			aria-labelledby="result-modal"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-xl" v-if="study">
			    <div class="modal-content">
			    	<div class="modal-header">
			    		<h5 class="modal-title">
							Study #<b>{{ study.id }}</b>
						</h5>
			    	</div>
			        <div class="modal-body">
						<div class="row">
							<div class="col-12 mb-4">
								<div class="row">
									<div class="col-12 max-200">
										ACCESSION NUMBER
									</div>
									<div class="col text-dark">
										<b>{{ study.accession_number }}</b>
									</div>
								</div>
								<div class="row">
									<div class="col-12 max-200">
										PATIENT
									</div>
									<div class="col text-dark">
										<b>{{ study.patient_name }}</b>
									</div>
								</div>
								<div class="row" v-if="study.returned_at && study.queued_at">
									<div class="col-12 max-200">
										TOTAL TIME
									</div>
									<div class="col text-dark">
										<b>{{ GetStudyTotalTime(study) }}</b>
									</div>
								</div>
								<div class="row">
									<div class="col-12 max-200">
										RESULT
									</div>
									<div class="col text-dark">
										<div
											class="badge text-uppercase font-16"
											:class="{
												'bg-danger': study.result_type == 'suspect',
												'bg-success': study.result_type == 'normal',
												'bg-warning': study.result_type == 'doubtful'
											}"
										>
											{{ study.result_type }}
										</div>
									</div>
								</div>
								<template v-if="study.result_type">
									<div class="row">
										<div class="col-12 max-200">
											SUSPECT QUADRANTS
										</div>
										<div class="col text-dark">
											<div class="row">
												<template v-if="study.quadrants.left && study.quadrants.left.length > 0">

													<div
														class="col-12"
														v-for="(quadrant, index) in study.quadrants.left"
														:key="index"
													>
														LEFT: <b>{{ quadrant.quadrant }}</b> | TYPE: <b>{{ quadrant.type }}</b> | SUBTYPE <b>{{ quadrant.subtype }}</b>
													</div>
												</template>
												<template v-if="study.quadrants.right && study.quadrants.right.length > 0">
													<div
														class="col-12"
														v-for="(quadrant, index) in study.quadrants.right"
														:key="index"
													>
														RIGHT: <b>{{ quadrant.quadrant }}</b> | TYPE: <b>{{ quadrant.type }}</b> | SUBTYPE <b>{{ quadrant.subtype }}</b>
													</div>
												</template>
											</div>
										</div>
									</div>
								</template>
							</div>
							<div class="col-12">
								<template v-if="study.heatmaps && study.heatmaps.length > 0">
									<div class="row">
										<div class="col-12">
											<h5>Heatmaps</h5>
										</div>
										<div
											class="col-auto position-relative"
											v-for="heatmap in study.heatmaps"
											:key="heatmap"
										>
											<div class="heatmap-description">
 
												<ul class="list-unstyled">
													<li 
														v-for="quadrant in GetQuadrants(heatmap)"
														:key="quadrant.id"
														class="text-withe"
													>
														<p class="text-white m-0">
															{{ quadrant.laterality == 'left' ? 'L' : 'R' }}: <b>{{ quadrant.quadrant }}</b> | TYPE: <b>{{ quadrant.type }}</b> | SUBTYPE <b>{{ quadrant.subtype }}</b>
														</p>
													</li>
												</ul>

											</div>

											<div
												class="heatmap-container"
												@click="ZoomImage(heatmap.file_name)"
											>
												<img :src="GetImageLink(heatmap.file_name, 'thumb')">

											</div>


										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	import moment from 'moment'
	require('moment-precise-range-plugin');

	export default {

		name: 'Index',

		data () {
			return {
				filters:	{
					mode: 		'all',
					search: 	'',
					more: 		false,
					date_type: 	'uploaded_at',
					start_at: 	null,
					ends_at: 	null,
					laterality: false
				},
				studies: 		[],
				study: 			{},
				items_per_page: 5,
				result_modal: 	null
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id', 'token'
			]),
			filtered_studies()
			{
				return this.studies.filter( study => {

					let allowed 	= true

					if(this.filters.mode != 'all')
					{
						allowed 		= this.filters.mode == study.result_type
					}

					return allowed
				})
			},
			headers() {

				let headers = [
					{ text: 'Our ID', value: 'id', sortable: true, type: Number },
					{ text: 'Accession number', value: 'accession_number', sortable: true, type: Number },
					{ text: 'Patient', value: 'patient_name', sortable: true, type: String },
					{ text: 'Type', value: 'result_type', sortable: true, type: String },
					{ text: 'Left', value: 'biggest_cat_left', sortable: true, type: String, align: 'center', laterality: true },
					{ text: 'Right', value: 'biggest_cat_right', sortable: true, type: String, align: 'center', laterality: true },
					{ text: 'Uploaded at', value: 'created_at', sortable: true, type: Date },
					{ text: '', value: null, sortable: false },
				]

				if ( this.filters.laterality ) return headers

				else return headers.filter(el => {
					if ( ! el.laterality ) return el
				})

			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ShowResult(item)
			{
				this.study 		= item

				this.result_modal.show()
			},
			ZoomImage(image)
			{
				Swal.fire({
					imageUrl: this.GetImageLink(image),
					showConfirmButton: false,
					imageWidth: 720,
					imageHeight: 720,
					imageAlt: image,
				})
			},
			GetImageLink(image, mode = null)
			{
				let size 	= ''

				switch(mode)
				{
					case 'thumb':
						size 		= '&width=100'
					break
					default:

					break
				}

				let url  = process.env.VUE_APP_API_URL
				url 	+= `/api/app/get-image/${this.organization_selected_id}/${image}`
				url 	+= `?token=${this.token}${size}`

				return url
			},
			GetStudyResults()
			{
				this.StartLoading()

				return window.api.call('post', '/get-study-results', {
						organization_id: 		this.organization_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.studies 		= data.studies
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			GetBiggestCat(laterality, study) {

				let cat = 0;

				if ( ! study.quadrants[laterality] ) return

				for (const quadrant of study.quadrants[laterality]) {
					
					let type = quadrant.type

					if (type == null || type == 'na') continue

					let quadrant_cat = parseInt(type.split('_')[1])
					
					if ( quadrant_cat > cat ) cat = quadrant_cat
					
				}

				return cat;

			},
			GetQuadrants(heatmap) {

				let quadrants = []

				if ( this.study.quadrants.left ) {
 
					for (const quadrant of this.study.quadrants.left) {

						quadrant.laterality = 'left'

						quadrants.push(quadrant)
						
					}

				}

				if ( this.study.quadrants.right ) {

					for (const quadrant of this.study.quadrants.right) {

						quadrant.laterality = 'right'

						quadrants.push(quadrant)
						
					} 

				}
				
				let all_quadrants = []

				for (const q of quadrants) {
					
					if ( q.quadrant_id == heatmap.quadrant_id ) all_quadrants.push(q)

				} 

				return all_quadrants ?? []

			},
			GetStudyTotalTime(study) {

				let queued_at = moment(study.queued_at)
				let returned_at = moment(study.returned_at) 

				return returned_at.preciseDiff(queued_at)

			}
		},
		mounted()
		{
			this.result_modal 		= new window.bootstrap.Modal(document.getElementById('result-modal'))
		},
		beforeMount()
		{
			this.GetStudyResults()

			if ( this.$route.params.search ) {

				this.filters.search = this.$route.params.search

			}
		},
		beforeUnmount() { 
			this.result_modal.hide()
		},
		
	}
</script>

<style lang="css" scoped>
	.heatmap-description {
		position: absolute;
		display: flex;
		top: 1px;
		bottom: 0;
		right: 0;
		left: 13px;
		height: fit-content;
		width: fit-content;
		padding: 5px 10px 0px 10px;
		background: #0000001f;
		border-radius: 6px;
	}
</style>