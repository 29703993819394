<template>
	<div class="mt-5 mt-md-0">
		<div class="container">
			<div class="row align-items-center justify-content-md-center justify-content-start">
				<div
					class="col-6 col-sm-4 col-md-3 col-lg-auto mb-4"
					v-for="app in apps"
					:key="app.role_id"
				>
					<div class="btn-container">
						<router-link
							:to="app.route"
							class="btn btn-tertiary home-link"
						>
							<div class="selectable">
								<div class="row h-100">
									<div class="col-12 text-center align-self-end">
										<i :class="`bi bi-${ app.icon }`"></i>
									</div>
									<div class="col-12 align-self-start">
										<p class="m-0">
											{{ app.name }}
										</p>
									</div>
									<div 
										class="favorite-button" 
										:class="{
											'favorited': IsFavorite(app.name)
										}"
										@click.prevent="FavoriteIcon(app.name)"
									>
										<i class="bi bi-heart-fill"></i>
									</div>
								</div>
							</div>
						</router-link>
						<div
							class="disable"
							@click="AuthAlert"
							v-if="NotAllowed(app)"
						></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Index',

		data () {
			return {
				apps: 	null
			}
		},
		computed: {
			...mapGetters('auth', [
				'roles', 'user'
			]),
			...mapGetters('system', [
				'menu_icons'
			])
		},
		methods: {
			...mapActions('auth', [
				'GetAuthUser'
			]),
			NotAllowed(app)
			{
				return app.role_area && this.roles.indexOf(app.role_area) === -1
			},
			AuthAlert()
			{
				Swal.fire({
					icon: 		'error',
					title: 		'Opss...',
					text: 		'You dont have permission to access this, contact your administration.'
				})
			},
			FavoriteIcon(icon_name) {

				window.api.call('post', 'toggle-favorite', {
					icon: icon_name
				})
					.then(({data}) => {

						if (!data.response) return

						this.GetAuthUser()

					})

			},
			IsFavorite(icon_name) {

				let settings = this.user.settings

				if ( ! settings ) return false

				let favorite_icons = settings.favorite_icons

				if ( ! favorite_icons ) return false
 
				return favorite_icons.indexOf(icon_name) > -1

			}
		},
		beforeMount() { 

			this.apps = this.menu_icons

		},
	}
</script>

<style lang="css" scoped>
	.btn-container
	{
		position: relative;
	}

	.btn-container .selectable
	{
		max-width: 140px;
		height: 140px;
	    transition: all .15s ease-in-out;
	}

	.btn-container .selectable i
	{
		font-size: 45px;
	}

	.btn-container .selectable p
	{
		text-align: center;
		font-size: 20px;
	}

	.btn-container .disable
	{
		position: absolute;
		border-radius: 16px;
		cursor: not-allowed;
		background-color: #dcecffb3;
		top: -1px;
		left: -1px;
		bottom: -1px;
		right: -1px;
		z-index: 1;
	}

	.home-link {
		width: 166px;
	}

	.favorite-button { 
		color: white;
		position: absolute;
		top: 10px;
		left: 10px;
		width: auto;
		line-height: 0;
		padding: 0;		  
		display: none;
		transition: ease-out;
		transition-duration: 200ms;
	}

	.btn-container:hover .favorite-button {
		display: initial; 
	}

	.favorite-button i {
		font-size: 15px !important;
	}

	.favorite-button:hover {
		color: rgb(255, 0, 0);
	}

	.favorited {
		color: rgb(255, 87, 87);
	}

</style>