<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 col-lg-5">
				<div class="row justify-content-md-between justify-content-end align-items-end pt-2 mb-4 flex-nowrap">
					<div class="col">
						<div class="mb-2 max-500 pr-6 ">
							<span>Organizations</span>
							<h4 class="font-24 font-md-32 m-0">All Organizations</h4>
						</div>
					</div>

					<div class="col-auto">
						<router-link 
							class="btn btn-success text-white px-4" 
							:to="{ name: 'CreateOrganization' }"
						>
							Create
						</router-link>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12">
						<template v-if="filtered_organizations.length > 0">
							<div
								v-for="link in filtered_organizations"
								:key="link.id"
							>
								<template v-if="link.accepted_at">
									<router-link
										:to="link.allowed ? { name: 'OrganizationProfile', params: { 
												organization_id: link.organization.id 
											}
										} : ''"
									>
										<div 
											class="select-card select-tertiary"
											:class="{ 'disabled': !link.allowed }"
										>
											<span class="text-uppercase">{{ link.organization.name }}</span>
											<i class="bi bi-chevron-right"></i>
										</div>
									</router-link>
								</template>
								<template v-else>
									<div class="select-card bg-gray-600 text-white p-4">
										<div class="row">
											<div class="col-12">
												<p class="font-20 text-center">Hello, you have been invited to join the "<b>{{ link.organization.name }}</b>", accept the invitation to proceed.</p>
											</div>
											<div class="col-12">
												<div class="d-flex justify-content-between">
													<button 
														type="button" 
														class="btn btn-danger px-3"
														@click="TakeDecision('no', link)"
													>
														Decline
													</button>
													<button 
														type="button" 
														class="btn btn-success text-white px-3"
														@click="TakeDecision('yes', link)"
													>
														Accept
													</button>
												</div>
											</div>
										</div>
									</div>
								</template>
								
							</div>
						</template>
						<template v-else>
							<p class="text-center"><b>Organizations not found, contact support.</b></p>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Index',

		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'all_organizations', 'all_roles'
			]),
			filtered_organizations()
			{
				return this.all_organizations.map( org => {

					org.allowed 	= false
					
					if(this.all_roles[org.organization.id])
					{
						org.allowed 		= this.all_roles[org.organization.id].indexOf('organization.area') > -1
					}

					return org
				})
			}
		},
		methods: { 
			...mapActions('system', [
                'StartLoading', 'FinishLoading'
            ]),
			...mapActions('auth', [
                'GetAuthUser'
            ]),
			TakeDecision(decision, link)
			{
				this.StartLoading()
				
				return window.api.call('post', '/take-decision', {
						decision: 				decision,
						organization_user_id: 	link.id
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								toast: 		true,
								showConfirmButton: false,
								title: 		'Invite successfully defined !',
								icon: 		'success',
								timer: 		3000,
								position: 	'top'
							})

						}else{

							Swal.fire({
								icon: 		'error',
								title: 		'Eww!',
								text: 		'An error has occurred on trying to take an decision, contact support.'								
							})
						}
					})
					.finally(() => {

						this.GetAuthUser()

						this.FinishLoading()
					})
			}
		}
	}
</script>

<style lang="css" scoped>


</style>