<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="d-block mx-auto mb-4 pb-md-2">
					<span>Equipment</span>
					<h4 class="m-0 font-24 font-md-32">All Equipments</h4>
				</div>
			</div>
		</div>	
		<div class="col-12 offset-md-8 col-md-4 mb-2">
			<div class="input-group">
				<span class="input-group-text" id="search-studies">
					<i class="bi bi-search"></i>
				</span> 
				<input 
					type="search" 
					class="form-control" 
					v-model="search" 
					placeholder="Search" 
					aria-label="Search" 
					aria-describedby="search-studies"
				>
			</div>
		</div>
		<div class="col-12">
			<DataTables
				:headers="headers"
				v-model="equipments"
				:search="search"
				:paginate="true"
				:items_per_page='5'
			>
				<template v-slot:body="{ item }">
					<td>
						<div class="td-content">
							#{{ item.id }}
						</div>
					</td>
					<td>
						<div class="td-content">
							S/N: <b> {{ item.serial_number }}</b>
						</div>
					</td>
					<td>
						<div class="td-content">
							<b>{{ item.station_name }}</b>
						</div>
					</td>
					<td>
						<div class="td-content">
							{{ item.created_at_parsed }}
						</div>
					</td>
					<td>
						<div class="td-content justify-content-end">
							<router-link
								:to="{ name: 'EquipmentProfile', params: { equipment_id: item.id }}"
								class="btn btn-tertiary btn-sm px-5 me-3"
							>
								show
								<i class="bi bi-search ms-2"></i>
							</router-link>
						</div>
					</td>
				</template>
			</DataTables>
		</div>	
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',

		data () {
			return {
				headers: 	[
					{ text: 'ID', value: 'id', sortable: true, type: Number },
					{ text: 'Serial Number', value: 'serial_number', sortable: true, type: String },
                  	{ text: 'Station Name', value: 'station_name', sortable: true, type: String },
                  	{ text: 'Created at', value: 'created_at', sortable: true, type: Date },
                  	{ text: '', value: null, sortable: false }
				],
				search: 	'',
				equipments: []
			}
		},
		computed: {
			...mapGetters('auth', [
				'organization_selected_id'
			])
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetEquipments()
			{
				this.StartLoading()

				window.api.call('post', '/get-equipments', {
						organization_id: this.organization_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.equipments 	= data.equipments

						}else{
							Swal.fire({
								icon: 	'error',
								title: 	'Oops...',
								text: 	'An error has occurred on get equipments, contact support !'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			}
		},	
		beforeMount()
		{
			this.GetEquipments()
		}
	}
</script>

<style lang="css" scoped>
</style>