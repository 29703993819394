<template>
	<header class="mb-5">
		<div class="row justify-content-center justify-content-md-between align-items-center">
			<div class="col-auto">
				<router-link :to="{ name: 'Home' }">
					<LogoSVG/>
				</router-link>
			</div>
			<div class="col-auto">
				<div class="row align-items-center pe-md-3 justify-content-center justify-content-md-end">
					
					<div v-for="(item, index) in favorites" :key="index" class="col-auto px-2"> 
						<router-link type="button" class="btn btn-tertiary btn-lg" :to="{ name: item.route.name }">
							<i :class="`bi bi-${item.icon}`"></i>
						</router-link> 
					</div>

					<div class="col-auto px-2 order-2 order-md-1">
						<router-link class="btn btn-tertiary btn-lg" :to="{ name: 'Home' }">
							<i class="bi bi-house"></i>
						</router-link>
					</div>
					<div class="col-auto px-2 order-3 order-md-2">
						<Notifications />
					</div>
					<div 
						class="col-12 col-md px-2 order-1 order-md-3 text-center mb-3 mb-md-0"
						:class="{ 'd-none': organizations.length < 2 }"
					>
						<Organizations />
					</div>				
					<div class="col-auto px-2 order-3">
						<router-link type="button" class="btn btn-tertiary btn-lg" :to="{ name: 'Profile' }">
							<i class="bi bi-person"></i>
						</router-link>
					</div>
					<div class="col-auto px-2 order-4">
						<a href="javascript:;" class="btn btn-tertiary btn-lg" @click="Logout">
							<i class="bi bi-box-arrow-right"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>

	import Organizations from './Organizations'
	import Notifications from './Notifications'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Navbar',
		components: {
			Organizations,
			Notifications
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'organizations', 'user'
			]),
			...mapGetters('system', [
				'menu_icons'
			]),
			favorites() {

				let settings = this.user.settings

				if ( ! settings ) return null

				let favorites = settings.favorite_icons

				if ( ! favorites) return null

				let filtered_items = []

				this.menu_icons.forEach((menu_item) => {

					if ( favorites.indexOf(menu_item.name) > -1 ) filtered_items.push(menu_item)

				})
 
				return filtered_items	
			}
		},
		methods: {
			...mapActions('auth', [
				'Logout'
			])
		}
	}
</script>

<style lang="css" scoped>
	header a
	{
		padding: 1rem 0;
	}

	header a i
	{
		font-size: 1rem;
		margin: 0 1.3rem;
	}
</style>