<template>
	<div class="upload-queue">
		<template v-if="queue_computed.length > 0">
			<div class="row align-items-center mb-4 mt-2">
				<div class="col-auto">
					<div class="d-flex justify-content-between">
						<template v-if="!complete">
							<button
								class="btn px-4 text-white"
								:class="{
									'btn-success': !uploading,
									'btn-tertiary': uploading
								}"
								@click="$emit('start-upload')"
								:disabled="uploading"
							>
								<template v-if="uploading">
									Uploading <i class="bi bi-clock-history ms-2"></i>
								</template>
								<template v-else>
									Start <i class="bi bi-cloud-upload ms-2"></i>
								</template>
							</button>
							<template v-if="!uploading">
								<button
									class="btn btn-danger ms-3"
									@click="$emit('clear-queue')"
								>
									Clear
								</button>
							</template>
						</template>
						<template v-else>
							<router-link
								:to="{ name: 'AIClassifications'}"
								class="btn btn-tertiary px-5"
							>
								Go to AI Classification
								<i class="bi bi-arrow-right ms-3"></i>
							</router-link>
						</template>
					</div>
				</div>
				<div class="col">
					<p class="me-4 text-right mb-0">
						<b>{{ (queue_computed.length).toString().padStart(2, '0') }}</b>
						selected file(s)
					</p>
				</div>
			</div>
			<transition-group tag="div" name="fade" mode="out-in">
				<div
					v-for="file in queue_computed"
					:key="file.id"
					class="card mb-3"
					:class="{
						'shadow-lg': 	file.current,
						'shadow-soft':	!file.current
					}"
				>
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-auto">
								<b>#{{file.position}}</b>
							</div>
							<div class="col">
								<div class="row">
									<div class="col-6">

										<div class="file-name">{{file.name}}</div>
										<small class="text-muted">
											<b>{{ file.size }}</b>
										</small>
									</div>
									<div class="col-6 d-flex justify-content-end align-items-end">
										<span class="mt-auto"
										>{{file.percentage}}%</span>
									</div>
									<div class="col-12">
										<div class="progress mb-0 mt-3">
										  	<div
										  		class="progress-bar"
										  		:class="{
										  			'bg-tertiary': !file.error && !file.completed,
										  			'bg-danger': file.error && file.completed,
										  			'bg-success': !file.error && file.completed
										  		}"
										  		role="progressbar"
										  		:style="`width: ${file.percentage}%`"
										  		:aria-valuenow="file.percentage"
										  		aria-valuemin="0"
										  		aria-valuemax="100"
										  	></div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-auto mt-3 mt-md-0 text-center">
								<template v-if="!uploading">
									<a
										href="javascript:;"
										class="text-danger"
										@click="$emit('remove-file', file.id)"
										:disabled="file.percentage > 0"
									>
										<i class="bi bi-trash font-24"></i>
									</a>
								</template>
								<template v-else-if="uploading && !file.completed">
									<i class="bi bi-clock font-24 text-tertiary"></i>
								</template>
								<template v-else-if="uploading && file.completed">
									<template v-if="file.error">
										<div class="badge bg-danger">
											error
										</div>
									</template>
									<template v-else>
										<i class="bi bi-check-lg font-24 text-success"></i>
									</template>
								</template>
							</div>
						</div>
					</div>
				</div>
			</transition-group>
		</template>
	</div>
</template>

<script>
	export default {

		name: 'UploadQueue',
		props: {
			queue: 	{
				type: 		Array,
				default: 	() => []
			},
			uploading: {
				type: 		Boolean,
				default: 	false
			},
			complete: {
				type: 		Boolean,
				default: 	false
			}
		},
		watch: {
			queue: {
				handler(arr)
				{
					this.queue_list 		= arr
				},
				deep: true,
				immediate: true
			},
			uploading: {
				handler(val)
				{

				},
				immediate: true
			}
		},
		computed: {
			queue_computed()
			{
				return this.queue_list
			}
		},
		data () {
			return {
				queue_list: 		[]
			}
		},
		methods: {

		}
	}
</script>

<style lang="css" scoped>

	.upload-queue .progress
	{
		height: 10px;
	}

	.upload-queue .file-name
	{
	    text-overflow: ellipsis;
	    max-width: 600px;
	    overflow: hidden;
	    white-space: nowrap;
	}

</style>