<template>
	<div>
		<a href="javascript:;" class="btn btn-tertiary btn-lg" @click="Open">
			<i class="bi bi-bell"></i>
			<template v-if="notifications.length > 0">
				<div class="notification-indicator">
					{{ notifications.length > 9 ? '9+' : notifications.length }}
				</div>
			</template>
		</a>
		<div 
			class="modal fade" 
			id="show-notifications" 
			tabindex="-1" 
			aria-labelledby="show-notifications" 
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg">
			    <div class="modal-content transparent">
				    <div class="row text-white">
				    	<div class="col-12">
	    					<p class="h4 text-center">Inbox</p>
	    				</div>
				    	<div class="col-12">
				    		<div class="px-4 pb-2">
				    			<div class="row align-items-center">
				    				<div class="col-auto">
				    					<div class="form-check inbox-check mb-0 px-0">
					    					<input 
					    						class="form-check-input mx-0" 
					    						type="checkbox" 
					    						id="select_all"
					    						:disabled="notifications.length === 0"
					    						v-model="select_all"
					    					> 
					    					<label 
					    						class="form-check-label" 
					    						for="select_all"
					    					></label>
					    				</div>
					    			</div>
					    			<div class="col">
					    				<div class="d-flex justify-content-end">
					    					<button
					    						type="button"
					    						class="btn btn-danger btn-sm px-5"
					    						:disabled="selected.length === 0"
					    						@click="DeleteNotifications"
					    					>
					    						Delete ({{ selected.length }})
					    					</button>
					    				</div>
					    			</div>
				    			</div>
				    		</div>
				    	</div>
				    	<template v-if="filtered_notifications.length > 0">						    	
				    		<div 
					    		class="col-12"
					    		v-for="notification in filtered_notifications"
					    		:key="notification.id"
					    	>
					    		<div class="card text-dark mb-3">
					    			<div class="px-4 py-3">
							    		<div class="row align-items-center">
							    			<div class="col-auto">
							    				<div class="form-check inbox-check mb-0 px-0">
							    					<input 
							    						class="form-check-input mx-0" 
							    						type="checkbox" 
							    						:id="`notification-${notification.id}`"
							    						v-model="selected"
							    						:value="notification.id"
							    					> 
							    					<label 
							    						class="form-check-label" 
							    						:for="`notification-${notification.id}`"
							    					></label>
							    				</div>
							    			</div>
							    			<div class="col-3">
							    				<p class="m-0 font-18">
							    					<b>{{ notification.sender_name }}</b>
							    				</p>
							    			</div>
							    			<div 
							    				class="col clickable" 
							    				@click="notification.show = !notification.show"
							    			>
							    				<p class="m-0 font-18">
							    					{{ notification.broadcast_content.title }}
							    				</p>
							    			</div>
							    			<div class="col-auto">
							    				<p class="m-0 text-muted">
							    					{{ notification.broadcast_content.created_at }}
							    				</p>
							    			</div>
							    			<transition name="fade" mode="out-in">
								    			<div class="col-12" v-if="notification.show" key="1">
								    				<div class="pt-3">
								    					{{ notification.broadcast_content.message }}
								    				</div>
								    			</div>
								    		</transition>
							    		</div>
							    	</div>
						    	</div>
					    	</div>
				    	</template>
				    	<template v-else>
				    		<template v-if="notifications.length > 0">
				    			<div class="col-12">
				    				<div class="col-12">
					    				<div class="card text-dark">
					    					<div class="px-4 py-3">
					    						<p class="m-0 text-center font-18">
					    							Your search based on "{{ search }}", returned no results 
					    						</p>
					    					</div>
					    				</div>
					    			</div>
				    			</div>
				    		</template>
				    		<template v-else>
				    			<div class="col-12">
				    				<div class="card text-dark">
				    					<div class="px-4 py-3">
				    						<p class="m-0 text-center font-18">
				    							You don't have notifications to show
				    						</p>
				    					</div>
				    				</div>
				    			</div>
				    		</template>
				    	</template>
				    </div>
			    </div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Notifications',

		data () {
			return {
				modal:  	null,
				select_all: false,
				selected: 	[],
				search: 	''
			}
		},
		computed: {
			...mapGetters('notification', [
				'notifications'
			]),
			filtered_notifications()
			{
				return this.notifications.map(n => {
					n.show 	= false

					return n
				})
			}
		},
		watch: {
			select_all(val)
			{
				if(val)
				{
					this.selected = this.filtered_notifications.map( n => n.id )
				
				}else{

					this.selected = []
				}
			}
		},
		methods: {
			...mapActions('notification', [
				'GetMyNotifications'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Open()
			{
				this.modal.show()
			},
			DeleteNotifications()
			{
				this.StartLoading()

				window.api.call('post', '/delete-notifications', {
						notification_ids: this.selected
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								toast: 		true,
								position: 	'top',
								timer: 		3000,
								showConfirmButton: false,
								icon: 		'success',
								title: 	 	'Notifications successfully deleted'
							})

						}else{

							Swal.fire({
								icon: 		'error',
								title: 	 	'Eww !!1',
								text: 		'An error has occurred on trying delete notifications, contact support.'
							})
						}
					})
					.finally(() => {

						this.selected 		= []
						this.select_all 	= false

						this.GetMyNotifications()						
						this.FinishLoading()
					})
			}
		},
		mounted()
		{
			const modal		= document.getElementById('show-notifications')
			this.modal 		= new window.bootstrap.Modal(modal)			
		}
	}
</script>

<style lang="css" scoped>
	a.btn
	{
		padding: 1rem 0;
	}

	a.btn i
	{
		font-size: 1rem;
		margin: 0 1.3rem;
	}

	.notification-indicator
	{
	    position: absolute;
	    top: -5px;
	    right: -5px;
	    border-radius: 50%;
	    border: 1px solid #ff5c5c;
	    padding: 1px;
	    background-color: #ff0000;
	    font-size: .865rem;
	    color: #fff;
	    font-weight: 600;
	    height: 25px;
	    width: 25px;
	}
</style>